<template>
	<div>
		<a-input-number placeholder="请输入" v-model="value" :min="min" :precision="precision"
			@focus="getCalculator()" />

		<div class="calculator-modal-back flex alcenter center" v-if="showCalculatorVisible">
			<div class="calculator-modal">
				<input class="input-box" type="text" size="21" maxlength="21" v-model="getValue" readonly="readonly">

				<div id="btn-list">
					<slot>

						<!-- <div class=" btn-30 btn-radius color-blue">+/-</div>
						<div @click="inputValue('%')" class=" btn-30 btn-radius color-blue">%</div> -->

						<div @click="inputValue('7')" class=" btn-30 btn-radius clear-marginleft">7</div>
						<div @click="inputValue('8')" class=" btn-30 btn-radius">8</div>
						<div @click="inputValue('9')" class=" btn-30 btn-radius">9</div>
						<div @click="inputValue('0')" class=" btn-70 btn-radius " v-if="precision==0">0</div>
						<div @click="inputValue('0')" class=" btn-30 btn-radius " v-else>0</div>
						<div @click="inputValue('.')" class=" btn-30 btn-radius "  v-if="precision!=0">.</div>
						<!-- <div @click="squareValue()" class=" btn-30 btn-radius color-blue font-14">×²</div>
						<div @click="radicalValue()" class=" btn-30 btn-radius color-blue font-12">√</div> -->
						<div @click="inputValue('4')" class=" btn-30 btn-radius clear-marginleft">4</div>
						<div @click="inputValue('5')" class=" btn-30 btn-radius">5</div>
						<div @click="inputValue('6')" class=" btn-30 btn-radius">6</div>
						<div @click="clearValue()" class=" btn-30 btn-radius color-red ">C</div>
						<div @click="backValue()" class=" btn-30 btn-radius color-red font-14 ">←</div>
						<!-- <div @click="inputValue('×')" class=" btn-30 btn-radius color-blue font-14">×</div>
						<div @click="inputValue('÷')" class=" btn-30 btn-radius color-blue font-12">÷</div> -->
						<div @click="inputValue('1')" class=" btn-30 btn-radius clear-marginleft">1</div>
						<div @click="inputValue('2')" class=" btn-30 btn-radius">2</div>
						<div @click="inputValue('3')" class=" btn-30 btn-radius">3</div>
						<!-- <div @click="inputValue('+')" class=" btn-30 btn-radius color-blue font-14">+</div>
						<div @click="inputValue('-')" class=" btn-30 btn-radius color-blue font-14">-</div> -->

						<div @click="calValue()" class=" btn-70 btn-radius color-red font-14 ">确认</div>
					</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				type: Number,
				default: 0,
			},
			min: {
				type: Number,
				default: 0,
			},
			precision: {
				type: Number,
				default: 0,
			}
		},
		data() {
			return {
				showCalculatorVisible: false,
				inputShow: {
					value: '0'
				}
			}
		},
		computed: {
			getValue: function() {
				return this.inputShow.value
			}
		},
		methods: {
			getCalculator() {
				this.showCalculatorVisible = true;
			},
			inputValue(param) {
				if (Object.prototype.toString.call(this.inputShow.value) == "[object Number]") { //判断输入框内容是否为数字类型
					this.inputShow.value = "0"; //数字类型说明是上个计算结果,清空内容
				}
				var str = '' + this.inputShow.value; //输入内容时,将输入框内容转为字符串类型
				
				if(this.precision==0){
					if(param=='.'){
						return;
					}
					var num = ('' + parseInt(str.split('').reverse().join(''))).split('').reverse().join('');
					var nlen = num.length;
					console.log(num);
					if ((num == '0' )) { //输入框内最后一串数字不为0时拼接字符串
						this.inputShow.value = str.substring(0, str.length - nlen) + param; //输入框内最后一串数字为0时,删除0拼接
					}else{
						this.inputShow.value += param; //拼接输入内容
					}
				}else{
					if(str.indexOf(".") == -1){
						var num = ('' + parseFloat(str.split('').reverse().join(''))).split('').reverse().join('');
						var nlen = num.length;
						console.log(num);
						if ((num == '0' )) { //输入框内最后一串数字不为0时拼接字符串
							if(param == '.'){
								this.inputShow.value += param; //拼接输入内容
							}else{
								this.inputShow.value = str.substring(0, str.length - nlen) + param; //输入框内最后一串数字为0时,删除0拼接
							}
						}else{
							this.inputShow.value += param; //拼接输入内容
						}
					}else{
						if(param != '.'){
							if(str.split('.')[1].length>=this.precision){
								return;
							}
							this.inputShow.value += param; //拼接输入内容
						}
					}
				}
				
			},
			clearValue() { //清空输入框内容
				this.inputShow.value = '0';
			},
			backValue() { //删除键,删除单个字符
				var str = this.inputShow.value;
				if (str.length == 1) {
					this.inputShow.value = "0";
				} else {
					this.inputShow.value = str.slice(0, str.length - 1);
				}
			},
			
			calValue(){
				let value = parseFloat(this.inputShow.value)
				this.$emit('input',value);
				this.showCalculatorVisible=false;
				this.inputShow.value=0;
			}
		}
	}
</script>

<style>
	.calculator-modal-back {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1001;
		background: rgb(0, 0, 0, 0.68);
	}

	.calculator-modal {
		width: 420px;
		/* height: 490px; */
		background: #ffffff;
		padding: 20px;
		border: 1px solid #e5e5e5;
		-moz-border-radius: 6px;
		-webkit-border-radius: 6px;
		border-radius: 6px;
		box-shadow: 0px 0px 10px #f2f2f2;
		-moz-box-shadow: 0px 0px 10px #f2f2f2;
		-webkit-box-shadow: 0px 0px 10px #f2f2f2;
	}

	.input-box {
		margin: 0;
		width: 380px;
		padding: 18px 10px;
		height: 60px;
		border: 1px solid #e5e5e5;
		border-radius: 6px;
		-webkit-border-radius: 6px;
		-moz-border-radius: 6px;
		background: #FFF;
		text-align: right;
		line-height: 60px;
		font-size: 24px;
		font-family: Verdana, Geneva, sans-serif;
		color: #666;
		outline: none;
		text-transform: uppercase;
	}

	#btn-list {
		width: 400px;
		overflow: hidden;
	}

	#btn-list .btn-radius {
		border-radius: 4px;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border: 1px solid #e5e5e5;
		background: -webkit-gradient(linear, 0 0, 0 100%, from(#f7f7f7), to(#ebebeb));
		background: -moz-linear-gradient(top, #f7f7f7, #ebebeb);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#f7f7f7, endColorstr=#ebebeb, grandientType=1);
		line-height: 58px;
		text-align: center;
		text-shadow: 0px 1px 1px #FFF;
		font-weight: bold;
		font-family: Verdana, Geneva, sans-serif;
		color: #666;
		float: left;
		margin-left: 22px;
		margin-top: 22px;
		font-size: 24px;
		cursor: pointer;
	}

	#btn-list .btn-radius:active {
		background: #ffffff;
	}

	#btn-list .clear-marginleft {
		margin-left: 0;
	}

	#btn-list .font-14 {
		font-size: 14px;
	}

	#btn-list .color-red {
		color: #ff5050
	}

	#btn-list .color-blue {
		color: #00b4ff
	}

	#btn-list .btn-30 {
		width: 58px;
		height: 58px;
	}

	#btn-list .btn-70 {
		width: 140px;
		height: 58px;
	}

	#btn-list .btn-70 {
		width: 140px;
		height: 58px;
	}
</style>
