<template>
	<div class="bg-w" style="height: 100%; overflow: auto;">
		<!-- <a-layout style="height: 100%;">
			<a-layout-header class="layout-classes-header">
				<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
					<a-menu-item key="goods">商品</a-menu-item>
					<a-menu-item key="service">服务</a-menu-item>
				</a-menu>
			</a-layout-header>
			<a-layout-content class="layout-classes-content">
				<div v-if="selectCurrent == 'goods'">
					<goods-data></goods-data>
				</div>
				<div v-if="selectCurrent == 'service'">
					<service-data></service-data>
				</div>
			</a-layout-content>
		</a-layout> -->
		
		<goods-data></goods-data>
	</div>
</template>

<script>
	import goodsData from './exhibitionArea/goods.vue';
	import serviceData from './exhibitionArea/service.vue';
	export default{
		components:{
			goodsData,
			serviceData,
		},
		data(){
			return{
				selectCurrent: 'goods',
			}
		},
		methods:{
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
		}
	}
</script>

<style>
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
		min-height: calc(100% - 100px);
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
