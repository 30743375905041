<template>
	<div class="have-taoka">
        <div class="loading" size="large" tip="正在加载已购套餐列表" v-if="loadingStatus">
            <a-spin tip="正在加载已购套餐列表"></a-spin>
        </div>
        <div class="container-2" v-if="loadingStatus == false">
           
            <div class="left">
                <div  v-if="list.length < 1" style="margin-top:100px;">
                    <a-empty description="没有已购套餐"/>
                </div>
                <div class="list" style="position:relative" v-if="list.length > 0">

                    <!-- selected="selected"  disabled="disabled" -->
                    <div class="item" v-for="(item,index) in list" :key="index" 
                        :class="
                            (item.shengyu_num < 1 && listIndex == index)?'item-selected-disabled':
                                (
                                    item.shengyu_num < 1?'item-disabled':
                                        (listIndex == index?'item-selected':'')
                                )
                        "
                        @click="setHavetaocanItem(index)"
                        >
                        <div class="item-top">
                            <p  class="name text-line2">
                                <span class="tag">限次</span> {{item.name}} 
                            </p>
                            <span class="status" v-if="item.shengyu_num < 1">- 已用光 -</span>
                        </div>
                        <p class="price"> 
                            ￥<span>{{item.total_price}}</span>
                        </p>
                        <p class="bottom">
                            <span >已用{{item.over_num}} / 剩余{{item.shengyu_num}}</span>
                            <span class="indate">有效期：{{item.valid_end_time}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="right">
                <div  v-if="itemList.length < 1" style="margin-top:100px;">
                    <a-empty description="没有可使用项目"/>
                </div>
                <div class="top-area"  v-if="itemList.length > 0"> 
                    可用商品
                    <p class="right-info"> 
                        以下商品
                        <span class="red">{{listInfo.valid_end_time}}到期</span>
                        ，  共剩余 
                        <span class="red">{{listInfo.shengyu_num}} </span>
                        次 <!---->
                    </p>
                </div>
                <div class="list-area" v-if="itemList.length > 0">
                  
                    <!-- disabled="disabled" -->
                    <div class="gds" v-for="(item,index) in itemList" :key="index" @click="selTaocanXm(index)"
                        :class="item.remain_num < 1?'gds-disabled':''"
                    >
                        <div class="flex-center goods_img_box img">
                            <img :src="item.cover_img" height="80px" width="80px">
                            <!---->
                        </div>
                        <div class="details" v-if="!item.type || item.type == 'xiangmu'">
                            <div class="top">
                                <p  class="name">
                                    <span class="tag">{{item.is_zs == 1?'赠送-':''}}项目</span> {{item.full_name}}
                                </p>
                                <span class="surplus-num">x{{item.remain_num}}</span>
                            </div>
                            <div class="bottom">
                                <!-- <p class="specs"></p> -->
                                <p class="price">零售价￥{{item.market_price}}</p>
                                <p class="price">套餐价￥{{item.market_price}} </p>
                            </div><!----><!---->
                        </div>
                        <div class="details" v-if="item.type && item.type == 'goods'">
                            <div class="top">
                                <p  class="name">
                                    <span class="tag">{{item.is_zs == 1?'赠送-':''}}产品</span> {{item.name}}
                                </p>
                                <span class="surplus-num">x{{item.remain_num}}</span>
                            </div>
                            <div class="bottom">
                                <!-- <p class="specs"></p> -->
                                <p class="price">零售价￥{{item.original_price}}</p>
                                <p class="price" v-if="item.is_zs != 1">套餐价￥{{item.original_price}} </p>
                                <p v-if="item.is_zs == 1" style="color:#2d8cf0" @click.stop="openServe(item)">服务记录</p>
                            </div><!----><!---->
                        </div>
                    </div>
                   
                   
                </div>
                
            </div>
        </div>
        <a-modal title="服务记录" :width="700" :visible="visible"  @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div>
						<a-button type="primary" @click="addvisible = true">添加记录</a-button>
					</div>
					
					<div class="mt20">
						<a-table :columns="servecolumns" :data-source="servedatas"
						:pagination="pagination"
						@change="handleTableChange"
						:loading="loading"
						rowKey="member_id"
						:scroll="{y: 300}"
						>
							
						 </a-table>
					</div>
				</div>
			</a-spin>
		</a-modal>
        <a-modal title="添加服务记录" :width="700" :visible="addvisible" v-if="addvisible"  @cancel="addvisible = false" :footer="null">
			<a-spin :spinning="loading" style="height:500px">
				<a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
						

						<a-form-model-item required label="文字内容">
							<a-textarea  v-model="form.content" :rows="5" />
						</a-form-model-item>
						
						<a-form-model-item required label="选择时间">
                            <a-date-picker show-time placeholder="选择时间"  :show-today="false"  @change="timeonChange" @ok="timeonOk" />
						</a-form-model-item>

                       
						
						<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
						      <a-button type="primary" @click="addonSubmit" :loading="loading">
						        保存发布
						      </a-button>
						</a-form-model-item>
					</a-form-model>	
			</a-spin>
		</a-modal>
    </div>

</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			getShowItemListNum(){
                
                return 0;
			}
		},
        watch:{
            cashierMember(newVal,oldVal)
            {
                this.initMemberId();
            },
            cashierCart(newVal,oldVal){
                this.initCashierCartChange();
            }
        },
		data(){
			return{
                loadingStatus:true,
                listIndex:-1,
                list:[],
                listInfo:{},
                itemList:[],
                visible:false,
                serverItem:{},
                pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				servecolumns: [
					{title: '内容',dataIndex: 'content',align: 'center'},
					{title: '日期',dataIndex: 'add_date',align: 'center'},
				],
				servedatas:[],
                loading:false,
                addvisible:false,
                
                labelCol: { span: 4 },
				wrapperCol: { span: 20 },
				form:{
					content:'',
                    add_date:''
				}
                
			}
		},
        created(){
           this.initMemberId();
        },
		methods:{
            timeonChange(e,value){
                this.form.add_date = value?value:'';
            },
            timeonOk(e,value){
                
            },
            addonSubmit(){
                if(!this.form.content)
                {
                    this.$message.error('请输入内容');
                    return ;
                }
                if(!this.form.add_date)
                {
                    this.$message.error('请选择时间');
                    return ;
                }
                if(!this.serverItem.member_once_card_item_id)
                {
                    this.$message.error('未选中使用的产品');
                    return ;
                }
                this.form.member_once_card_item_id = this.serverItem.member_once_card_item_id
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('serveradd',this.form).then(res=>{
					this.$message.success('保存成功');
                    this.addvisible = false;
					this.loading=false;
                    this.getServeList();
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
            handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getServeList();
			},
            handleCancel(){
                this.visible = false;
            },
            openServe(item){
                this.visible = true;
                this.serverItem = item;
                this.getServeList();
                console.log('openServe',item)
            },
            getServeList(){
                if(this.loading==true) return;
				this.loading=true;
				this.$http.api('serverlist',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					member_once_card_item_id:this.serverItem.member_once_card_item_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.servedatas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
            },
            selTaocanXm(index){
                var member_once_card_id = this.list[this.listIndex].member_once_card_id;
                var once_card_id = this.list[this.listIndex].once_card_id;
                var once_card_order_id = this.list[this.listIndex].once_card_order_id;
                var itemInfo = this.itemList[index];
                if(itemInfo.remain_num < 1)
                {
                    this.$message.error('可用数量不足');
                    return ;
                }
                
                var cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
                var  isIn = false;
                cashierCart.forEach((nowitem,nowindex)=>{
                    if(nowitem.member_once_card_id && nowitem.member_once_card_id == member_once_card_id)
                    {
                        if(!itemInfo.type || itemInfo.type == 'xiangmu')
                        {
                            if(itemInfo.project_id == nowitem.goods_id)
                            {
                                //如果他已经存在了 就给这个数量+1
                                nowitem.num = nowitem.num + 1;
                                nowitem.quota = itemInfo.keyong;
                                isIn = true; //记录已经存在了
                            }
                        }else if(itemInfo.type && itemInfo.type == 'goods')
                        {
                            if(itemInfo.goods_id == nowitem.goods_id)
                            {
                                //如果他已经存在了 就给这个数量+1
                                nowitem.num = nowitem.num + 1;
                                nowitem.quota = itemInfo.keyong;
                                isIn = true; //记录已经存在了
                            }
                        }
                    }
                })
                if(!isIn)
                {
                    // var itemInfo = {
                    //     goods_id:186,
                    //     cover_img:'',
                    //     name:'测试的商品',
                    //     num:1,
                    //     original_price:500,
                    //     type:'goods',
                    //     is_zs:1
                    // }
                    if(!itemInfo.type || itemInfo.type == 'xiangmu')
                    {
                        var obj = {
                            goods_id:itemInfo.project_id,
                            num:1,
                            type:'xiangmu',
                            name:itemInfo.full_name,
                            cover_img:itemInfo.cover_img,
                            original_price:itemInfo.market_price,
                            vip_price:itemInfo.market_price,
                        }
                    }else if(itemInfo.type && itemInfo.type == 'goods')
                    {
                        var obj = {
                            goods_id:itemInfo.goods_id,
                            num:1,
                            type:'goods',
                            name:itemInfo.name,
                            cover_img:itemInfo.cover_img,
                            original_price:itemInfo.original_price,
                            vip_price:itemInfo.original_price,
                        }
                    }
                    
                    // var obj = {
                    //     goods_id:92,
                    //     num:1,
                    //     type:'xiangmu',
                    //     name:'赠送的头疗',
                    //     cover_img:'',
                    //     original_price:100,
                    //     vip_price:100,
                    // }
                    obj = Object.assign(obj,{
                        is_zs:itemInfo.is_zs?itemInfo.is_zs:0,
                        sku_id:0,
                        quota:itemInfo.keyong,
                        is_vip_discount: 0,
                        member_once_card_item_id:itemInfo.member_once_card_item_id,
                        member_once_card_id,
                        once_card_id,
                        once_card_order_id
                    })
                    cashierCart.push(obj)
                }
                this.$store.commit('setCashierCart',cashierCart);
                this.opeCashierCartTcUserPrice();////调用common/mixin/common.js下的重置价格计算方法
            },
            initCashierCartChange(){
                var cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
                var checkObj = [];
                cashierCart.forEach((item,index)=>{
                    if(item.member_once_card_item_id)
                    {
                        if(!item.type || item.type == 'xiangmu')
                        {
                            var key = item.member_once_card_item_id+'_'+item.goods_id+'_xiangmu';
                        }else if(item.type && item.type == 'goods'){
                            var key = item.member_once_card_item_id+'_'+item.goods_id+'_goods';
                        }
                        
                        
                        //var key = item.member_once_card_id+'_'+item.goods_id;
                        checkObj[key] = item.num;
                    }
                })
                this.list.forEach((item,index)=>{
                    var over_num = 0,shengyu_num = 0;
                    item.items.forEach((c_item,c_index)=>{
                        if(!c_item.type || c_item.type == 'xiangmu')
                        {
                            var key = c_item.member_once_card_item_id+'_'+c_item.project_id+'_xiangmu';
                        }else if(c_item.type && c_item.type == 'goods'){
                            var key = c_item.member_once_card_item_id+'_'+c_item.goods_id+'_goods';
                        }
                        var haveNum = checkObj[key]?parseInt(checkObj[key]):0;
                        //console.log('c_item',c_item)
                        var remain_num = parseInt(c_item.keyong) - parseInt(haveNum);
                        c_item.remain_num = remain_num;
                        shengyu_num = shengyu_num + remain_num;
                        over_num = over_num + (c_item.total_num - c_item.remain_num);
                    })
                    item.shengyu_num = shengyu_num;
                    item.over_num = over_num;
                })
                
                if(this.list.length > 0)
                {
                    this.setHavetaocanItem(this.listIndex == -1?0:this.listIndex)
                }else{
                    this.setHavetaocanItem(-1);
                }
                // console.log('checkObj',checkObj)
                // console.log('cashierCart',cashierCart);
            },
            initMemberId(){
                this.member_id = this.cashierMember.member_id?this.cashierMember.member_id:0;
                this.getHavetaocan();
            },
            getHavetaocan(){
                this.loadingStatus = true;
				this.$http.api('havetaocan',{
                    member_id:this.member_id,
					limit:200,
					page:1,
					name:'',
				}).then(res=>{
                    this.loadingStatus = false;
					
                    this.list = res.list;
                    
                    if(this.list.length > 0)
                    {
                        this.list.forEach((item,index)=>{
                            item.items.forEach((c_item,c_index)=>{
                                c_item.keyong = c_item.remain_num;
                            })
                        })
                    }else{
                       
                    }
                    this.initCashierCartChange();
                    
				}).catch(res=>{
                    this.loadingStatus = false;
				})
			},
            setHavetaocanItem(index)
            {
                console.log('setHavetaocanItem',index)
                this.listIndex = index;
                if(index == -1)
                {
                    this.itemList = [];
                    this.listInfo = {};
                }else{
                    var itemsList = JSON.parse(JSON.stringify(this.list[index].items));
                    // itemsList.forEach((item,index)=>{
                    //     item.keyong = item.remain_num;
                    // })
                    this.itemList = itemsList;
                    this.listInfo = this.list[index];
                }
               
                //console.log('setHavetaocanItem', this.itemList)
            }
		}
	}
</script>

<style>
.loading{
    height: calc(100vh - 270px);
    display: flex;
    align-items:center;
    justify-content:center;
    width:100%
}
.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.have-taoka{
    margin-top:10px;
    
}
 .have-taoka .container-2{
    height: calc(100vh - 270px);
    display: flex;
    overflow:hidden;
}


.have-taoka .container-2 .right {
    flex: 1;
    width: 10%;
    margin-left: 10px;
    border: 1px solid #d7dde4;
    border-radius: 4px;
    position: relative;
    padding-bottom: 80px;
}
.have-taoka .container-2 .right .top-area {
    height: 48px;
    font-size: 16px;
    line-height: 1.2;
    padding: 0 20px;
    display: flex;
    align-items: center;
}
.have-taoka .container-2 .right .top-area .right-info {
    margin-left: 10px;
    flex: 1;
    font-size: 14px;
    color: #515a6e;
    text-align: right;
    margin-bottom:0;
}
.have-taoka .container-2 .right .top-area .right-info .red {
    color: #ed4014;
    margin: 0 2px;
}
.have-taoka .container-2 .right .list-area {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    padding: 0 10px 20px 20px;
    overflow-y: auto;
    height: calc(100vh - 270px);
    width: 100%;
    padding-bottom:100px;
}
.have-taoka .container-2 .right .list-area .gds {
    padding: 5px;
    background: #ffffff;
    border: 1px solid #abdcff;
    border-radius: 4px;
    display: flex;
    width: calc(33.3% - 10px);
    margin: 10px 10px 0 0;
    position: relative;
    overflow: hidden;
}
@media screen and (max-width: 1700px)
{
    .have-taoka .container-2 .right .list-area .gds {
        width: calc(50% - 10px);
    }
}
@media screen and (max-width: 1500px){
    .have-taoka .container-2 .right .list-area .gds {
        width: 100%;
    }
}

.have-taoka .container-2 .right .list-area .gds .goods_img_box {
    position: relative;
    background-color: #f8f8f9;
    flex: none;
    overflow: hidden;
}

.have-taoka .container-2 .right .list-area .gds .img {
    height: 80px;
    width: 80px;
    flex: none;
    border-radius: 4px;
}
.have-taoka .container-2 .right .list-area .gds .details {
    margin-left: 10px;
    padding-top: 3px;
    color: #515a6e;
    line-height: 1;
    position: relative;
    flex: 1;
    width: 50%;
}
.have-taoka  .container-2 .right .list-area .gds-disabled .details {
    color: #808695;
}
.have-taoka .container-2 .right .list-area .gds .details .top {
    display: flex;
}
.have-taoka .container-2 .right .list-area .gds .details .top .name {
    flex: 1;
    line-height: 1.2;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.have-taoka .container-2 .right .list-area .gds .details .top .name .tag {
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    color: #fff;
    background: #f90;
    margin-right: 3px;
    height: 16px;
    line-height: 16px;
    padding: 0 2px;
    border-radius: 2px;
}
.have-taoka .container-2 .right .list-area .gds .details .top .surplus-num {
    flex: none;
    margin-left: 20px;
}
.have-taoka .container-2 .right .list-area .gds .details .bottom {
    /* position: absolute;
    bottom: 3px;
    left: 0;
    z-index: 1; */
}
.have-taoka .container-2 .right .list-area .gds .details .bottom .specs {
    font-size: 12px;
    color: #808695;
}
.have-taoka .container-2 .right .list-area .gds .details .bottom .price {
    font-size: 14px;
    font-weight: 800;
    margin-top: 2px;
    margin-bottom:10px;
}
.have-taoka .container-2 .right .list-area .gds-disabled {
    background: #f8f8f9;
    border-color: #dcdee2;
}
.have-taoka .container-2 .right .list-area .gds-disabled .details .name .tag {
    background: #808695;
}
.have-taoka .container-2 .right .list-area .gds .details .icon-sell-out {
    display: none;
}
.have-taoka .container-2 .right .list-area .gds-disabled .details .icon-sell-out {
    display: block;
    position: absolute;
    bottom: -36px;
    right: -26px;
    border-radius: 50%;
    height: 70px;
    width: 70px;
    border: 2px solid #808695;
    line-height: 40px;
    color: #808695;
    font-size: 12px;
    text-align: center;
    transform: rotate( -30deg);
}
.have-taoka .container-2 .right .list-area .gds-disabled .details .icon-sell-out:before {
    display: block;
    content: "";
    position: absolute;
    height: 60px;
    width: 60px;
    border: 1px dashed #808695;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}












.have-taoka .container-2 .left{
    width: 330px;
    border: 1px solid #d7dde4;
    border-radius: 4px;
    flex: none;
}
.have-taoka .container-2 .left .list{
    position: relative;
    padding: 10px;
    line-height: 1;
    flex: 1;
    height: calc(100vh - 270px);
    overflow-y: auto;
}
.have-taoka .container-2 .left .list .item{
    border: 1px solid #abdcff;
    border-radius: 6px;
    color: #515a6e;
    background: #f0faff;
    padding: 10px;
    min-width: 270px;
}

.have-taoka .container-2 .left .list .item .item-top{
    display: flex;
}
.have-taoka .container-2 .left .list .item .name{
    flex: 1;
    font-size: 16px;
    line-height: 1.5;
    height: 3em;
    margin-top: -.2em;
    margin-bottom: 5px;
    font-weight: 800;
    letter-spacing: .5px;
}
.have-taoka .container-2 .left .list .item .name .tag{
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.5;
    padding: 0 6px;
    border-radius: 100px;
    background: #f90;
    color: #fff;
    vertical-align: middle;
    margin-top: -5px;
}
.have-taoka .container-2 .left .list .item .price{
    font-size: 14px;
    margin-bottom: 17px;
}
.have-taoka .container-2 .left .list .item .price span{
    font-size: 28px;
}
.have-taoka .container-2 .left .list .item .bottom{
    font-size: 12px;
    color: #808695;
    margin-bottom:0px;
}
.have-taoka .container-2 .left .list .item .bottom .indate{
    float: right;
    text-align: right;
}

.have-taoka .container-2 .left .list .item+.item{
    margin-top: 10px;
}

.have-taoka  .container-2 .left .list .item-selected {
    background: #5cadff;
    border-color: #5cadff;
    color: #fff;
}

.have-taoka  .container-2 .left .list .item-selected .bottom{
    color: hsla(0,0%,100%,.8);
}

.have-taoka .container-2 .left .list .item-disabled{
    /* background: #f8f8f9; */
    background: #eeeeee;
    border-color: #dcdee2;
    color: #808695;
}
.have-taoka .container-2 .left .list .item-disabled .name .tag {
    background: #808695;
}

.have-taoka  .container-2 .left .list .item-selected-disabled {
    border-color: #5cadff;
}

.have-taoka  .container-2 .left .list .item-selected-disabled .bottom {
    color: unset;
}

.have-taoka .container-2 .left .list .item .status{
    display: none;
}

.have-taoka .container-2 .left .list .item-disabled .status{
    display: block;
    flex: none;
    color: #515a6e;
    margin-left: 5px;
}

.text-line1, .text-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-line2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

</style>
