<template>
	<div>
		<a-modal title="选择规格" :width="700" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer">
				<div class="flex center">
					<a-button class="cancel-button" key="back" @click="handleCancel">
						取消
					</a-button>
					<a-button class="sure-button" key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
						确认
					</a-button>
				</div>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex alcenter">
						<img v-if="goods.cover_img" :src="goods.cover_img" class="choose-goods-sku-cover-img"/>
						<img v-else src="../../../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="choose-goods-sku-cover-img"/>
						<div class="ml20">
							<div class="ft14 ftw600 cl-black text-over4">{{goods.name}}</div>
							<div v-if="canChooseSku.length<1">
								
								<div class="mt20 flex alcenter">
									<div class=" ft14 cl-price">会员价￥{{goods.vip_price}}</div>
									<div class="ml8 ft14 cl-notice">原价￥{{goods.original_price}}</div>
								</div>
							</div>
							<div v-else>
								<div class=" mt10 ft14 cl-info">{{canChooseSku[selectIndex].name}}(库存：{{canChooseSku[selectIndex].quota}})</div>
								<div class="mt10 flex alcenter">
									<div class=" ft14 cl-price">会员价￥{{canChooseSku[selectIndex].vip_price}}</div>
									<div class="ml8 ft14 cl-notice">原价￥{{canChooseSku[selectIndex].original_price}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="mt20">
						<div class="ft12 ftw400 cl-main">选择规格：</div>
						<div class="flex wrap mt10" v-if="canChooseSku.length>0">
							<div class="choose-goods-sku-item" :class="{active : selectIndex == index}" 
							v-for="(item,index) in canChooseSku" @click="chooseIt(index)">{{item.name}}</div>
						</div>
						<div class="flex center mt10" v-else>
							<a-empty description="规格全部被选完了"/>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
			goods:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				selectIndex:0,
			}
		},
		computed:{
			canChooseSku(){
				let canChooseSku=new Array;
				let sku=this.goods.sku;
				let sku_ids=new Array;
				for(var j in this.cashierCart){
					if(this.goods.goods_id== this.cashierCart[j].goods_id){
						sku_ids.push(this.cashierCart[j].sku_id)
					}
				}
				
				for(var i in sku){
					if(sku_ids.indexOf(sku[i].sku_id) == -1){
						canChooseSku.push(sku[i]);
					}
				}
				return canChooseSku;
			}
		},
		
		methods:{
			chooseIt(index){
				this.selectIndex=index;
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
				
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.selectIndex<0){
					this.$message.error('请选择规格');
					return false;
				}
				
				if(this.canChooseSku.length <1){
					this.$message.error('该商品的规格已经全部被选完了');
					return false;
				}
				
				let sku_data=this.canChooseSku[this.selectIndex];
				
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				
				let cart=JSON.parse(JSON.stringify(this.cashierCart));
				for(var i in cart){
					if(cart[i].type=='goods'){
						if(this.goods.goods_id == cart[i].goods_id){
							if(sku_data.sku_id == cart[i].sku_id){
								this.$message.error('该规格已经选过了');
								return false;
							}
						}
					}
				}
				
				cart.push({
					goods_id:this.goods.goods_id,
					num:1,
					type:'goods',
					name:this.goods.name,
					cover_img:this.goods.cover_img,
					original_price:this.goods.original_price,
					vip_price:this.goods.vip_price,
					sku_id:sku_data.sku_id,
					sku_name:sku_data.name,
					sku_original_price:sku_data.original_price,
					sku_vip_price:sku_data.vip_price,
					quota:sku_data.quota,
				});
				this.$store.commit('setCashierCart',cart);
				
				this.$emit("ok");
				this.confirmLoading=false;
			},
		}
	}
</script>

<style>
	.choose-goods-sku-cover-img{
		width: 60px;
		height: 80px;
	}
	
	.choose-goods-sku-item{
		padding: 6px 15px;
		border-radius: 12px;
		font-size: 16px;
		font-weight: 500;
		color: #000000;
		border: 1px solid #EBEDF5;
		margin-right: 20px;
		margin-bottom: 20px;
	}
	
	.choose-goods-sku-item.active{
		border: 1px solid #4772FF;
		color: #4772FF;
	}
</style>
