<template>
	<div class="pd10 cart-service"  :class="{active :index==cashierCartIndex}"  @click="chooseIt()">
		<div class="flex alcenter">
			<img v-if="datas.cover_img" :src="datas.cover_img" class="cart-service-img"/>
			<img v-else src="../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="cart-service-img"/>
			<div class="ml10 alcenter" style="width: 100%;">
				<div class="ft14 ftw400 cl-black text-over4">{{datas.abbreviation}}</div>
				<div class="mt8 flex alcenter space">
					<div>
						<div class="ft14 cl-price mt30">原价￥{{datas.market_price}}</div>
					</div>
					<div>
						<div class="flex end">
							<div class="flex alcenter">
								<i class="iconfont iconminus_small ft22 cl-notice" v-if="datas.num<=1"/>
								<i class="iconfont iconminus_small ft22 cl-green" @click.stop.prevent="reduce()" v-else/>
								<div class="ft16 cl-black ml8 mr10">{{datas.num}}</div>
								<i class="iconfont iconadd_small1 ft22 cl-theme" @click.stop.prevent="sum()" />
							</div>
						</div>
						<div class="mt8 flex end ft14 cl-price">实付￥{{price}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="cart-service-iden">服务</div>
		
		<div class="cart-service-choosed" style="line-height: 20px;" v-if="index == cashierCartIndex">
			<i class="iconfont iconduihao ft10 cl-w  ml20" />
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
			index:{
				type:Number,
				default:-1,
			}
		},
		computed:{
			price(){
				return ((parseFloat(this.datas.market_price)*100 * this.datas.num)/100).toFixed(2);
			}
		},
		data(){
			return{
				
			}
		},
		methods:{
			reduce(){
				if(this.datas.num<1){
					this.$message.error('无法继续减了');
					return false;
				}
				let cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
				cashierCart[this.index].num=this.datas.num-1;
				this.$store.commit('setCashierCart',cashierCart);
			},
			sum(){
				 //e.stopPropagation();//阻止点击事件向上冒泡
				// if(this.datas.num>=this.datas.quota){
				// 	this.$message.error('无法继续加了');
				// 	return false;
				// }
				let cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
				cashierCart[this.index].num=this.datas.num+1;
				this.$store.commit('setCashierCart',cashierCart);
			},
			chooseIt(){
				this.$store.commit('setCashierCartIndex',this.index);
			},
		}
	}
</script>

<style>
	.cart-service{
		border-bottom: 1px solid #f5f5f5; 
		line-height: 1; 
		position: relative;
	}
	
	.cart-service.active{
		background: #ECF5FF;
		border: 1px solid #D0E7FF;
	}
	.cart-service-img{
		width: 70px;
		height: 80px
	}
	
	.cart-service-iden{
		position: absolute;
		top:10px;
		left:10px;
		
		background: red;
		color: #ffffff;
		padding: 1px 4px;
		font-size: 12px;
		border-radius: 2px;
	}
	
	.cart-service-choosed{
		position: absolute;
		top:0;
		right:0;
		width: 0; height: 0;
		 border-color: transparent #F56C6C; /*上下颜色 左右颜色*/
		 border-width: 0 40px 40px 0;
		 border-style: solid;
	}
</style>
