<template>
	<div>
		<a-modal title="修改数量" :width="540" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer">
				<div class="flex center">
					<a-button class="cancel-button" key="back" @click="handleCancel">
						取消
					</a-button>
					<a-button class="sure-button" key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
						确认
					</a-button>
				</div>
			</template>
			<a-spin :spinning="loading">
				<div>
					<div class="flex alcenter">
						<img v-if="goods.cover_img" :src="goods.cover_img" class="choose-goods-num-cover-img"/>
						<img v-else src="../../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="choose-goods-num-cover-img"/>
						<div class="ml20" v-if="goods.type=='goods'">
							<div class="ft14 ftw600 cl-black text-over4">{{goods.name}}</div>
							<div v-if="goods.sku_id==0">
								<div class=" mt10 ft14 cl-info">库存：{{goods.quota}}</div>
								<div class="mt10 flex alcenter">
									<div class=" ft14 cl-price">会员价￥{{goods.vip_price}}</div>
									<div class="ml8 ft14 cl-notice">原价￥{{goods.original_price}}</div>
								</div>
							</div>
							<div v-else>
								<div class=" mt10 ft14 cl-info">{{goods.sku_name}}(库存：{{goods.quota}})</div>
								<div class="mt10 flex alcenter">
									<div class=" ft14 cl-price">会员价￥{{goods.sku_vip_price}}</div>
									<div class="ml8 ft14 cl-notice">原价￥{{goods.sku_original_price}}</div>
								</div>
							</div>
						</div>
						<div class="ml20" v-else-if="goods.type=='xiangmu'">
							<div class="ft14 ftw600 cl-black text-over4">{{goods.name}}</div>
							<div >
								<div class="mt10 flex alcenter">
									<div class="ml8 ft14 cl-notice">原价￥{{goods.original_price}}</div>
								</div>
							</div>
						</div>
						<div class="ml20" v-else-if="goods.type=='taocan'">
							<div class="ft14 ftw600 cl-black text-over4">{{goods.name}}</div>
							<div >
								<div class="mt10 flex alcenter">
									<div class="ml8 ft14 cl-notice">原价￥{{goods.original_price}}</div>
								</div>
							</div>
						</div>
						<div class="ml20" v-else>
							<div class="ft14 ftw600 cl-black text-over4">{{goods.name}}</div>
							<div >
								<div class="mt20 flex alcenter">
									<div class=" ft14 cl-price">市场价￥{{goods.market_price}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="mt20">
						<div class="flex alcenter">
							<div class="ft14 ftw500 cl-info mr10">请输入数量:</div>
							<calculator-tool v-model="num" :min="0" :precision="0" ></calculator-tool>
							<div class="ft14 ftw400 cl-notice ml8">个</div>
						</div>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import calculatorTool from '../../../../../../components/tool/calculator.vue';
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
		},
		components:{
			calculatorTool,
		},
		data(){
			return {
				loading:false,
				confirmLoading:false,
				num:1,
			}
		},
		computed:{
			goods(){
				if(this.cashierCartTabType == 'goods')
				{
					return this.cashierCart[this.cashierCartIndex];
				}else if(this.cashierCartTabType == 'taocan')
				{
					return this.cashierCartTaocan.list[this.cashierCartTaocan.selListIndex];
				}
				
			}
		},
		created() {
			this.num=this.goods.num;
			console.log('this.goods',this.goods)
		},
		
		methods:{
			
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
				
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.num<=0 ){
					this.$message.error('数量不能小于0');
					return false;
				}
				if(this.num > this.goods.quota)
				{
					this.$message.error('数量不足，最多使用“'+this.goods.quota+'”个');
					return false;
				}
				
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				if(this.cashierCartTabType == 'goods')
				{
					let cart=JSON.parse(JSON.stringify(this.cashierCart));
					cart[this.cashierCartIndex].num=this.num;
					this.$store.commit('setCashierCart',cart);
					this.opeCashierCartTcUserPrice(); //调用common/mixin/common.js下的重置价格计算方法
				
				}else if(this.cashierCartTabType == 'taocan')
				{
					let cashierCartTaocan=JSON.parse(JSON.stringify(this.cashierCartTaocan));
					cashierCartTaocan.list[this.cashierCartTaocan.selListIndex].num=this.num;
					this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
					this.opeCashierCartTaocanTcUserPrice(); //调用common/mixin/common.js下的重置价格计算方法
				}
				
				this.$emit("ok");
				this.confirmLoading=false;
			},
		}
	}
</script>

<style>
	.choose-goods-num-cover-img{
		width: 60px;
		height: 80px;
	}
</style>
