<template>
	<div>
        <div class="search" style="display: none">
            <div style="display: flex; align-items: center;">
                <div  class="tabs_selected">
                    <div  class="tabs_item " :class="type == 1?'tabs_item-active':''" @click="changeType(1)">充值</div>
                    <div class="tabs_item " :class="type == 2?'tabs_item-active':''" @click="changeType(2)">等级卡</div>
                    <div class="tabs_item " :class="type == 3?'tabs_item-active':''" @click="changeType(3)">储值卡</div>
                </div>
                <div style="display: flex;align-items:center;">
                    <a-input placeholder="请输入名称查询" class="search-input"></a-input>
                </div>
            </div>
        </div>
        <div class="fullMoney" v-if="type == 1">
            <div class="list-money list-01">
                <div class="item">
                    <div class="item-info">
                        <div class="text">充值默认账户<a-button  class="button">点击充值</a-button></div>
                        <div  class="text">余额:￥1111</div>
                    </div>
                    <div  class="item-info-bottom">
                        <div  class="bottom">
                            <span  class="price">本金:￥1111</span>
                            <span  style="flex: 1 1 0%;"></span>
                            <span  class="btn-details">赠金:￥0</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="scroll-list-chongzhi" v-if="type == 2">
            <div  class="fullGrad">
                <div class="list-chongzhi2 list-01">
                    <div  class="item" v-for="(item,index) in 50">
                        <div  class="item-info">
                            <div  title="注册会员" class="name text-line2">注册会员</div>
                            <div  class="bottom">
                                <span  class="price">￥0</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <div class="scroll-list-chongzhi" v-if="type == 3">
            <div  class="fullGrad fullCard">
                <div class="list-chongzhi2 list-01">
                    <div  class="item" v-for="(item,index) in balanceList" :key="index">
                        <div  class="item-info" @click="selRecharge(index,3)">
                            <div  :title="'储值卡'+(index+1)" class="name text-line2">储值卡{{ index+1 }}</div>
                            <div  class="bottom">
                                <span  class="price">￥{{item.recharge_price}}</span>
                                <span  class="price" style="margin-left: 10px;">赠送￥{{ item.give_price }}</span>
                                <!-- <span  class="btn-details">详情</span> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    </div>

</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
			isVip: {
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			price(){
				
			}
		},
		data(){
			return{
                visible:false,
                confirmLoading:false,
				type:3, //1充值卡 2等级卡 3储值卡
                balanceList:[]
			}
		},
        created(){
            this.getShowbalance();
        },
		methods:{
            selRecharge(index,type){
                if(type == 3)
                {
                    var info = this.balanceList[index];
                    var cashierCartRecharge = JSON.parse(JSON.stringify(this.cashierCartRecharge));
                    cashierCartRecharge = cashierCartRecharge?cashierCartRecharge:{};
                    var info = {
                        selType:type,
                        recharge_price:info.recharge_price,
                        give_price:info.give_price
                    }
                    cashierCartRecharge = Object.assign(cashierCartRecharge,info);
                    this.$store.commit('setCashierCartRecharge',cashierCartRecharge);
                    this.opeCashierCartRechargeTcUserPrice();////调用common/mixin/common.js下的重置价格计算方法
                }
            },
            getShowbalance(){
                this.$http.api('showbalance',{
					
				}).then(res=>{
                    this.balanceList = res.list;
					
				}).catch(res=>{
					
				})
            },
            changeType(type){
                this.type = type;
                
            },
			
		}
	}
</script>

<style>
.scroll-list-chongzhi{
    width: calc(100% );
    height: calc(100vh - 230px);
    overflow-y: auto;
    position: relative;
    padding: 0 20px;
}
.fullGrad .list-chongzhi2{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    min-height: calc(100% + 5px);
    padding-top: 5px;
}
.fullGrad .list-chongzhi2 .item{
    width: calc(20% - 10px);
    height: 100px;
    margin: 0 10px 10px 0;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
}

@media screen and (max-width: 1600px){
    .fullGrad .list-chongzhi2 .item {
        width: calc(25% - 10px);
        }
}
@media screen and (max-width: 1400px){
    .fullGrad .list-chongzhi2 .item{
        width: calc(33.3% - 10px);
    }

}

.fullGrad .list-chongzhi2 .item .item-info{
    color: #17233d;
    font-size: 14px;
    line-height: 1.5;
    position: relative;
    margin-left: 10px;
    flex: 1;
}


.list-chongzhi2 .item:hover, .list-chongzhi2 .item[selected] {
    background: #e5f6ff;
    border-color: #2d8cf0;
}



.text-line1, .text-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-line2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.fullGrad .list-chongzhi2 .item .item-info .bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
}
.fullGrad .list-chongzhi2 .item .item-info .bottom .price{
    font-weight: 800;
}

.fullCard .list-chongzhi2 .item .item-info .bottom{
    justify-content: space-between;
}


.fullCard .list-chongzhi2 .item .item-info .bottom .btn-details:hover {
    font-weight: 700;
}

.fullCard .list-chongzhi2 .item .item-info .bottom .btn-details {
    color: #5cadff;
    cursor: pointer;
    text-align: right;
    margin-bottom: -2px;
}

.fullMoney{
    padding: 0 20px;
}

.fullMoney .list-chongzhi2{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    min-height: calc(100% + 5px);
    padding-top: 5px;
    
}


.fullMoney .list-money .item{
    width: 350px;
    height: 130px;
    border: 1px solid #ecdbc0;
    border-radius: 4px;
    background: #ecdbc0;
    cursor: pointer;
}
.fullMoney .list-money .item .item-info{
    color: #fff;
    font-size: 16px;
    font-weight: 800;
    line-height: 48px;
    position: relative;
    background: #e4b88a;
}

.fullMoney .list-money .item .item-info .text {
    margin-left: 10px;
}

.fullMoney .list-money .item .item-info .text .button {
    line-height: 16px;
    border-radius: 3px;
    margin-left: 150px;
    color: #fff;
    font-size: 14px;
    font-weight: 800;
    padding: 5px;
    background: #e4b88a;
    border: 1px solid #fff;
    cursor: pointer;
}

.fullMoney .list-money .item .item-info-bottom{
    color: #999;
    font-size: 14px;
    line-height: 1;
    position: relative;
    background: #e4c09b;
}
.fullMoney .list-money .item .item-info-bottom .bottom {
    position: absolute;
    top: 12px;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.fullMoney .list-money .item .item-info-bottom .bottom .price {
    margin-left: 10px;
    margin-top: 10px;
}

.fullMoney .list-money .item .item-info-bottom .bottom .btn-details {
    margin-right: 10px;
    margin-top: 10px;
}

.search{
    padding: 20px 20px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: none !important;
    margin-bottom: 10px;
}	
.search .tabs_selected {
    display: flex;
    margin: 0px 10px 0px 0;
    background: #e5f6ff;
    border-radius: 20px;
}

.search .tabs_selected .tabs_item{
    width: 75px;
    height: 35px;
    line-height: 35px;
    background: #e5f6ff;
    color: #2d8cf0;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}
.search .tabs_selected .tabs_item-active{
    background: #2d8cf0;
    color: #fff;
}
.search .search-input {
    position: relative;
    width: 250px;
    margin: 0px 10px 00px 0;
}
</style>
