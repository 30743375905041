<template>
	<div>
		<a-modal title="选择会员" :width="700" :visible="visible"  @cancel="handleCancel" :footer="null">
			<a-spin :spinning="loading">
				<div>
					<div>
						<a-button type="primary" @click="chooseYouKeAct()">游客</a-button>
					</div>
					<div class="mt10">
						<a-input-search size="large" v-model="search.keyword" placeholder="输入会员手机号或者扫描会员码" enter-button @search="onSearch" />
					</div>
					
					<div class="mt20">
						<a-table :columns="columns" :data-source="datas"
						:pagination="pagination"
						@change="handleTableChange"
						:loading="loading"
						rowKey="member_id"
						:scroll="{y: 500}"
						>
							<div slot="face" class="choose-member-face" slot-scope="face,record">
								<img v-if="face" :src="face"/>
								<img v-else src="../../../../../../assets/image/woyaoyou_head_defult@2x.png"/>
							</div>
							
							<div slot="level"  slot-scope="level,record">
								{{level ? level.name : '普通会员'}}
							</div>
							
							<div slot="action"  slot-scope="record">
								<a-button type="primary" @click="chooseIt(record)">选择</a-button>
							</div>
						 </a-table>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../../common/mixin/list.js';
	export default{
		props:{
			visible:{
				type:Boolean,
				default:false,
			},
		},
		mixins:[listMixin],
		data(){
			return {
				loading:false,
				confirmLoading:false,
				search:{
					keyword:'',
				},
				pagination: {
					current: 1,
					pageSize: 5, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: '会员头像',dataIndex: 'face',align: 'center',scopedSlots: {customRender: 'face'}},
					{title: '会员昵称',dataIndex: 'nick_name',align: 'center',ellipsis:true},
					{title: '会员等级',dataIndex: 'level',align: 'center',scopedSlots: {customRender: 'level'}},
					{title: '手机号',dataIndex: 'mobile',align: 'center',ellipsis:true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}},
				],
				datas:[]
			}
		},
		
		methods:{
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getChooseMemberList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					keyword:this.search.keyword,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			
			onSearch(){
				this.pagination.current=1;
				this.getLists();
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
				
			chooseIt(record){
				var cashierMember = this.cashierMember;
				if(cashierMember.member_id && record.member_id != cashierMember.member_id)
				{
					this.changecashierCart();
				}
				this.$store.commit('setMember',record);
				this.$emit("ok");
			},
			
			chooseYouKeAct(){
				var cashierMember = this.cashierMember;
				if(cashierMember.member_id)
				{
					this.changecashierCart();
				}
				this.$store.commit('setMember',{});
				this.$emit("ok");
			},
			changecashierCart()
			{
				var cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
				var newCashierCart = [];
				cashierCart.forEach((item,index)=>{
					if(item.member_once_card_id)
					{
						//如果是选择的个人套餐  需要删除掉
					}else{
						newCashierCart.push(item);
					}
				})
				this.$store.commit('setCashierCart',newCashierCart);
				this.opeCashierCartRechargeTcUserPrice();////调用common/mixin/common.js下的重置价格计算方法
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.choose-member-face img{
		width: 48px;
		height: 48px;
	}
</style>
