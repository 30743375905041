<template>
	<div style="height: 100%;">
		<div class="bg-w pd10" style="height: 100%;">
			<div class="flex " style="height: 100%;">
				<div class="cash-register-left">
					<div class="pd10">
						<a-input-search placeholder="请输入条形码" enter-button @search="onSearch" />
					</div>
					<div class="cash-register-left-info">
						<div class="flex alcenter space">
							<div v-if="JSON.stringify(cashierMember)=='{}'" class="cl-price flex alcenter" style="line-height: 1;" @click="chooseMemberAct()">
								<i class="iconfont iconorder_operation_coach ft12  mr5" />
								选择会员
							</div>
							<div v-else>
								<div class="cl-theme flex alcenter" style="line-height: 1;" @click="chooseMemberAct()">
									{{cashierMember.nick_name}} <div class="ml5 cl-red" @click="chooseMemberAct()">重新选择</div>
								</div>
								<div v-if="cashierMember.level === null" style="padding-top:5px;">
									{{'普通会员'}}
								</div>
								<div v-else style="padding-top:5px;">
									{{cashierMember.level.name + '（' + cashierMember.level.discount + '折）'}}
								</div>
							</div>
							<div class="ft16 ftw500 cl-main">购物车</div>
						</div>
					</div>
					
					<div class="cash-register-left-content">
						<template v-for="(item,index) in cashierCart" v-if="cashierCartTabType == 'goods'">
							<cart-goods v-if="item.type=='goods' || item.type=='xiangmu'" @selTcUserFuncCallback="selTcUserFuncCallback" :datas="item" :isVip="cashierMember.level" :index="index"></cart-goods>
							<cart-service v-if="item.type=='service'" :datas="item" :index="index"></cart-service>
						</template>

						<template v-for="(item,index) in cashierCartTaocan.list" v-if="cashierCartTabType == 'taocan' && cashierCartTaocan.type == 1">
							<cart-taocan  @selTcUserFuncCallback="selTcUserFuncCallback" :datas="item" :index="index"></cart-taocan>
							
						</template>

						<div  class="memRechange"  v-if="cashierCartTabType == 'chongzhi'">
							<div class="mem_discounts">
								<div  title="充值默认账户">充值默认账户</div>
								<span class="devPersonal" @click="selTcUserFuncCallback({
																		index:-1,
																		type:'cashierCartRecharge'
																	})">{{cashierCartRecharge.fcUserList?('已选 '+cashierCartRecharge.fcUserList.length+' 人'):'选提成人'}}</span>
							</div>
							<div class="mem_price">
								<div>充值金额</div>
								<a-input-number style="width: 100px;" :min="0" @change="rechargeInfoChange('recharge_price')" v-model="rechargeInfo.recharge_price"></a-input-number>
							</div>
							<div class="mem_givePrice">
								<div>优惠赠送</div>
								<!-- <a-select style="width: 150px;" placeholder="请选择">
									<a-select-option :value="0">全部状态</a-select-option>
									<a-select-option :value="1">待确认</a-select-option>
								</a-select> -->
								<!-- class="input-money" -->
								<a-input-number :min="0" placeholder="输入赠送金额" @change="rechargeInfoChange('give_price')" v-model="rechargeInfo.give_price"></a-input-number>
							</div>
						</div>
					</div>

					<div class="cash-register-left-bottom">
						<div class="flex alcenter space" v-if="cashierCartTabType == 'goods'">
							<div>
								<div class="flex alcenter">
									<div class="cl-black">合计</div>
									<div class="cl-theme">{{getTotalNum}}件</div>
									<div class="cl-black">商品</div>
								</div>
								<div class="mt10">
									<div class="cl-green ft16">优惠￥{{getYouhuiPrice}}</div>
								</div>
							</div>

							<div>
								<div >
									<div class="cl-black flex end">合计￥{{getTotalPrice}}</div>
									
								</div>
								<div class="mt10 ">
									<div class="cl-price ft16 flex end">应收￥{{getNeedPay}}</div>
								</div>
							</div>
						</div>

						<div class="flex alcenter space" v-if="cashierCartTabType == 'taocan'">
							<div>
								<div class="flex alcenter">
									<div class="cl-black">合计</div>
									<div class="cl-theme">{{cashierCartTaocan.list.length}}个</div>
									<div class="cl-black">套餐</div>
								</div>
								
							</div>

							<div v-if="cashierCartTaocan.type == 1">
								<div >
									<div class="cl-black flex end">合计￥{{getTotalPriceTaocan}}</div>
									
								</div>
								<div class="mt10 ">
									<div class="cl-price ft16 flex end">应收￥{{getTotalPriceTaocan}}</div>
								</div>
							</div>
							<div v-if="cashierCartTaocan.type == 2">
								<div >
									<div class="cl-black flex end">使用套餐中0个项目</div>
									
								</div>
								<div class="mt10 ">
									<div class="cl-price ft16 flex end">应收0</div>
								</div>
							</div>
						</div>

						<div class="flex alcenter space" v-if="cashierCartTabType == 'chongzhi'">
							<div>
								<div class="flex alcenter">
									<div class="cl-black">到账金额￥</div>
									<div class="cl-theme">{{ getRechargeTotalPrice }}元</div>
									<div class="cl-black"></div>
								</div>
							</div>

							<div>
								<div >
									<div class="cl-black flex end">充值￥{{rechargeInfo.recharge_price?rechargeInfo.recharge_price:0}}</div>
									
								</div>
								<div class="mt10 ">
									<div class="cl-price ft16 flex end">应收￥{{rechargeInfo.recharge_price?rechargeInfo.recharge_price:0}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="cash-register-right">
					<div class=" text-center " style="padding: 15px 0px;">常用操作</div>
					<div class="cash-register-right-action" style="border-top:1px solid #EFF0F0 ;" @click="editNumAct()">数量
						<a-icon type="form" class="ft14" />
					</div>
					<div class="cash-register-right-action" @click="delAct()">删除
						<a-icon type="delete" class="ft14" />
					</div>
					<div class="cash-register-right-action" @click="guaAct()" v-if="cashierCartTabType == 'goods'">挂单
						<a-icon type="database" class="ft14" />
					</div>
					<div class="cash-register-right-button" style="padding:0 10px;">
						<div class="cash-register-right-button-blue" v-if="cashierCartTabType == 'goods' || cashierCartTabType == 'taocan'"
							style="margin-bottom:10px;" @click="clearCart()">清空菜单</div>
						<div class="cash-register-right-button-red" @click="payAct()">结算</div>
					</div>
				</div>
			</div>
			
			<div v-if="editNumVisible">
				<edit-num :visible="editNumVisible" @cancel="cancelEditNum" @ok="okEditNum"></edit-num>
			</div>
			<div v-if="chooseMemberVisible">
				<choose-member :visible="chooseMemberVisible" @cancel="cancelChooseMember" @ok="okChooseMember"></choose-member>
			</div>
		</div>
		<div v-if="showSelUserStatus">
			<sel-tc-user :showSelUserStatus="showSelUserStatus" :initInfo="showSelUserObj" @cancel="showSelUserCancel"></sel-tc-user>
		</div>

	</div>
</template>

<script>
	import cartTaocan from './cashRegister/taocan.vue';
	import cartGoods from './cashRegister/goods.vue';
	import cartService from './cashRegister/service.vue';
	import editNum from './cashRegister/modal/editNum.vue';
	import chooseMember from './cashRegister/modal/chooseMember.vue'
	import selTcUser from '../newindex/sel-tc-user.vue';
	export default {
		components:{
			cartGoods,
			cartService,
			editNum,
			chooseMember,
			selTcUser,
			cartTaocan
		},
		data() {
			return {
				editNumVisible:false,
				chooseMemberVisible:false,
				rechargeInfo:{
					recharge_price:'',
					give_price:''
				},
				showSelUserStatus:false,
				showSelUserObj:{}
			}
		},
		created(){
			//this.$store.commit('setCashierCartTaocan','');
			if(this.cashierCartRecharge.selType)
			{
				this.rechargeInfo = JSON.parse(JSON.stringify(this.cashierCartRecharge)); //给充值赋值
			}
			
		},
		watch:{
			// cashierMember(newVal,oldVal)
            // {
			// 	if(newVal.member_id != oldVal.member_id)
			// 	{
			// 		//切换人的时候 判断购物车中的套餐
			// 		var cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
			// 		var newCashierCart = [];
			// 		cashierCart.forEach((item,index)=>{
			// 			if(item.member_once_card_id)
			// 			{
			// 				//如果是选择的个人套餐  需要删除掉
			// 			}else{
			// 				newCashierCart.push(item);
			// 			}
			// 		})
			// 		this.$store.commit('setCashierCart',newCashierCart);
            //     	this.opeCashierCartRechargeTcUserPrice();////调用common/mixin/common.js下的重置价格计算方法
			// 	}
            //     console.log('newVal',newVal,oldVal)
            // },
			cashierCartRecharge(newVal,oldVal){
				this.rechargeInfo = JSON.parse(JSON.stringify(newVal));
			},
		},
		computed:{
			getRechargeTotalPrice(){
				var recharge_price = parseFloat(this.rechargeInfo.recharge_price);
				recharge_price = isNaN(recharge_price)?0:recharge_price;
				var give_price = parseFloat(this.rechargeInfo.give_price);
				give_price = isNaN(give_price)?0:give_price;
				return recharge_price + give_price;
			},
			// 实付总价
			getTotalPriceTaocan(){
				let total_price=0;
				if(this.cashierCartTaocan.list.length>0){
					let price=0;
					for( var i in this.cashierCartTaocan.list){
						price=this.cashierCartTaocan.list[i].original_price;
						total_price += (parseFloat(price)*100 * this.cashierCartTaocan.list[i].num)/100
					}
				}
				return total_price.toFixed(2);
			},
			// 实付总价
			getTotalPrice(){
				let total_price=0;
				if(this.cashierCart.length>0){
					let price=0;
					for( var i in this.cashierCart){
						if(this.cashierCart[i].type=='goods'){
							if(this.cashierCart[i].sku_id>0){
								price=this.cashierCart[i].sku_original_price;
							}else{
								price=this.cashierCart[i].original_price;
							}
							if(JSON.stringify(this.cashierMember)!='{}'){
								if(this.cashierMember.level_id>0){
									if(this.cashierCart[i].sku_id>0){
										price=this.cashierCart[i].sku_vip_price;
									}else{
										price=this.cashierCart[i].vip_price;
									}
								}
							}
						}else if(this.cashierCart[i].type=='xiangmu'){
							price=this.cashierCart[i].original_price;
						}else{
							price=this.cashierCart[i].market_price
						}
						if(this.cashierCart[i].member_once_card_id)
						{
							price = 0; //如果是选择的套餐里面的话	
						}
						total_price += (parseFloat(price)*100 * this.cashierCart[i].num)/100
					}
				}
				return total_price.toFixed(2);
			},
			// 不参与折扣总价 is_vip_discount（0不参与，1参与）
			getNoDiscountTotalPrice(){
				let total_price = 0;
				if(this.cashierCart.length > 0){
					let price = 0;
					for( var i in this.cashierCart){
						if(JSON.stringify(this.cashierMember) !== '{}'){
							if (this.cashierCart[i].is_vip_discount === 0) price = this.cashierCart[i].vip_price;
						}
						total_price += parseFloat(price)
					}
				}
				return total_price.toFixed(2);
			},
			// 商品总数
			getTotalNum(){
				let num=0;
				if(this.cashierCart.length>0){
					for( var i in this.cashierCart){
						num+=this.cashierCart[i].num;
					}
				}
				return num;
			},
			// 应收金额
			getNeedPay(){
				let need_pay=0;
				if(this.cashierCart.length>0){
					let price=0;
					for( var i in this.cashierCart){
						if(this.cashierCart[i].type=='goods'){
							if(this.cashierCart[i].sku_id>0){
								price=this.cashierCart[i].sku_original_price;
							}else{
								price=this.cashierCart[i].original_price;
							}
							if(JSON.stringify(this.cashierMember)!='{}'){
								if(this.cashierMember.level_id>0){
									if(this.cashierCart[i].sku_id>0){
										price=this.cashierCart[i].sku_vip_price;
									}else{
										price=this.cashierCart[i].vip_price;
									}
								}
							}
						}else if(this.cashierCart[i].type=='xiangmu'){
							price=this.cashierCart[i].original_price;
						}else{
							price=this.cashierCart[i].market_price;
						}
						if(this.cashierCart[i].member_once_card_id)
						{
							price = 0; //如果是选择的套餐里面的话	
						}
						need_pay += (parseFloat(price)*100 * this.cashierCart[i].num)/100
					}
				}
				return (need_pay - this.getYouhuiPrice).toFixed(2);
			},
			// 优惠价格
			getYouhuiPrice(){
				let discount = 1 // 默认无折扣
				let discount_total_price = 0 // 需打折总价
				let youhui_price = 0 // 默认优惠价格0
				if (this.cashierMember.level !== undefined && this.cashierMember.level !== null) { // 非普通会员
					discount = (parseFloat(this.cashierMember.level.discount) / 10).toFixed(2)
					discount_total_price = parseFloat(this.getTotalPrice) - parseFloat(this.getNoDiscountTotalPrice)
					youhui_price = ( discount_total_price - (discount_total_price * discount)).toFixed(2)
				}
				return youhui_price
			}
		},
		methods: {
			showSelUserCancel(){
				this.showSelUserStatus = false;
			},
			selTcUserFuncCallback(e){
				if(e.type == 'cashierCartRecharge')
				{
					if(!this.cashierCartRecharge || !this.cashierCartRecharge.recharge_price)
					{
						this.$message.error('请先选择或输入金额');
						return ;
					}
				}
				this.showSelUserObj = e;
				this.showSelUserStatus = true;
			},
			rechargeInfoChange(key){
				var money = this.rechargeInfo[key];
				if(isNaN(money))
				{
					return ;
				}
				this.cashierCartRecharge[key] = money;
                this.$store.commit('setCashierCartRecharge',this.cashierCartRecharge);
				this.opeCashierCartRechargeTcUserPrice();////调用common/mixin/common.js下的重置价格计算方法
			},
			onSearch(value) {
				
			},
			
			chooseMemberAct(){
				this.chooseMemberVisible=true;
			},
			cancelChooseMember(){
				this.chooseMemberVisible=false;
			},
			okChooseMember(){
				this.chooseMemberVisible=false;
			},
			checkIsGoods()
			{
				if(this.cashierCartTabType == 'goods' || this.cashierCartTabType == 'taocan')
				{
					return true;
				}
				this.$message.error('仅项目和商品可以进行该操作');
				return false;
			},
			editNumAct(){
				if(!this.checkIsGoods())
				{
					return false;
				}
				if(this.cashierCartTabType == 'goods'){
					if(this.cashierCartIndex==-1){
						this.$message.error('请选择商品');
						return false;
					}
					if(!this.cashierCart[this.cashierCartIndex]){
						this.$message.error('请选择商品');
						return false;
					}
					this.editNumVisible=true;
				}else if(this.cashierCartTabType == 'taocan')
				{
					if(this.cashierCartTaocan.type != 1)
					{
						this.$message.error('不支持修改数量');
						return false;
					}
					if(this.cashierCartTaocan.selListIndex==-1){
						this.$message.error('请选择套餐');
						return false;
					}
					if(!this.cashierCartTaocan.list[this.cashierCartTaocan.selListIndex]){
						this.$message.error('请选择套餐');
						return false;
					}
					this.editNumVisible=true;
				}
				
			},
			cancelEditNum(){
				this.editNumVisible=false;
			},
			okEditNum(){
				this.editNumVisible=false;
			},
			
			delAct(){
				if(this.cashierCartTabType == 'chongzhi')
				{
					this.$store.commit('setCashierCartRecharge',{});
					this.$message.success('已清空充值金额');
					return true;
				}else if(this.cashierCartTabType == 'taocan')
				{
					if(this.cashierCartTaocan.selListIndex==-1){
						this.$message.error('请选择套餐');
						return false;
					}
					if(!this.cashierCartTaocan.list[this.cashierCartTaocan.selListIndex]){
						this.$message.error('请选择套餐');
						return false;
					}
					let cashierCartTaocan=JSON.parse(JSON.stringify(this.cashierCartTaocan));
					cashierCartTaocan.list.splice(this.cashierCartTaocan.selListIndex,1);
					cashierCartTaocan.selListIndex = -1;
					this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
					return true;
				}
				if(this.cashierCartIndex==-1){
					this.$message.error('请选择商品');
					return false;
				}
				
				if(!this.cashierCart[this.cashierCartIndex]){
					this.$message.error('请选择商品');
					return false;
				}
				let cart=JSON.parse(JSON.stringify(this.cashierCart));
				cart.splice(this.cashierCartIndex,1);
				this.$store.commit('setCashierCart',cart);
				this.$store.commit('setCashierCartIndex',-1);
			},
			
			guaAct(){
				if(!this.checkIsGoods())
				{
					return false;
				}
				if(this.cashierCart.length<=0){
					this.$message.error('还没有商品，无法挂单');
					return false;
				}
				
				let gua = JSON.parse(JSON.stringify(this.gua));
				gua.unshift({
					member:this.cashierMember,
					cart:this.cashierCart,
					total_price:this.getTotalPrice,
					total_num:this.getTotalNum,
					need_pay:this.getNeedPay,
					add_time_format:this.getTime(),
				})
				this.$store.commit('setGua',gua);
				this.$store.commit('setCashierCart',[]);
				this.$store.commit('setCashierCartIndex',-1);
				this.$store.commit('setMember',{});
			},
			
			clearCart(){
				if(this.cashierCartTabType == 'goods')
				{
					this.$store.commit('setCashierCart',[]);
					this.$store.commit('setCashierCartIndex',-1);
				}else if(this.cashierCartTabType == 'taocan')
				{
					var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
					if(cashierCartTaocan.type == 1)
					{
						cashierCartTaocan.list = [];
						cashierCartTaocan.selListIndex = -1;
					}
					this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
				}
				
			},
			
			payAct(){
				if(this.cashierCartTabType == 'goods')
				{
					if(this.cashierCart.length<=0){
						this.$message.error('还没有商品，无法结算');
						return false;
					}
				}else if(this.cashierCartTabType == 'taocan')
				{
					if(this.cashierCartTaocan.list.length<=0){
						this.$message.error('还没有选择套餐，无法结算');
						return false;
					}
				}else if(this.cashierCartTabType == 'chongzhi')
				{
					console.log('this.cashierCartRecharge',this.cashierCartRecharge)
					if(!this.cashierCartRecharge.recharge_price || this.cashierCartRecharge.recharge_price<=0
						|| this.cashierCartRecharge.recharge_price == ''
					){
						this.$message.error('充值金额不能小于等于0');
						return false;
					}
				}
				
				this.$router.push('/cash/pay');
			},
			getTime(){
				const date = new Date();
				const year = date.getFullYear();
				const month = date.getMonth() + 1;
				const day = date.getDate();
				const hour = date.getHours();
				const minute = date.getMinutes();
				const second = date.getSeconds();
				this.month = check(month);
				this.day = check(day);
				this.hour = check(hour);
				this.minute = check(minute);
				this.second = check(second);
							
				function check(i) {
					const num = (i < 10) ? ("0" + i) : i;
					return num;
				}
							
				return year + "-" + this.month + "-" + this.day + " " + this.hour + ":" + this.minute + ":" + this.second;
			}
		}
	}
</script>

<style>

.memRechange {
    position: absolute;
    z-index: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    height: 86px;
}

.memRechange, .memRechange>div{
    display: flex;
    justify-content: space-between;
}
.memRechange>div {
    flex-direction: column;
}

.memRechange .mem_discounts{
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 5px;
}

.memRechange .devPersonal{
	display: inline-block;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
    color: #808695;
    text-align: center;
    cursor: pointer;
    height: 32px;
    line-height: 30px;
    font-size: 13px;
}
.memRechange .mem_givePrice, .memRechange .mem_price{
    margin-right: 5px;
    text-align: center;
}
.memRechange .mem_givePrice .input-money{
	position: absolute;
    bottom: -16px;
    right: 25px;
    width: 120px;
}


	.cash-register-left {
		width: 75%;
		height: 100%;
		position: relative;
	}

	.cash-register-left-info {
		padding: 15px 10px;
		background: #F5F5F5;

	}

	.cash-register-left-bottom {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		padding: 10px 10px;
		background: #F5F5F5;
	}

	.cash-register-right {
		width: 25%;
		background: #F5F7FA;
		border: 1px solid #F2F3F5;
		height: 100%;
		position: relative;
	}

	.cash-register-right-action {
		border-bottom: 1px solid #EFF0F0;
		font-size: 16px;
		font-weight: 500;
		color: #000000;
		padding: 15px 0;
		text-align: center;
		cursor: pointer;

	}

	.cash-register-right-button {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
	}

	.cash-register-right-button-blue {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background: #4772FF;
		text-align: center;
		cursor: pointer;
		border-radius: 4px;
		color: #ffffff;
	}

	.cash-register-right-button-red {
		width: 100%;
		height: 40px;
		line-height: 40px;
		background: red;
		text-align: center;
		cursor: pointer;
		border-radius: 4px;
		color: #ffffff;
	}
	
	.cash-register-left-content{
		height: calc(100% - 200px);
		overflow: auto;
	}
</style>
