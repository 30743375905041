<template>
	<div >
		<div class="pd10">
			<div class="form-search-box ">
				<a-form layout="inline">
					<a-form-item label="名称">
						<a-input v-model="search.abbreviation" placeholder="请输入商品名称"></a-input>
					</a-form-item>
					
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
			
			<div class=" pd20">
				<div class="flex wrap">
					<template v-for="(item,index) in getDatas">
						<div class="service-data-item" :class="{active : item.is_select==1}" >
							<img v-if="item.cover_img" :src="item.cover_img" class="service-data-item-cover-img"/>
							<img v-else src="../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="service-data-item-cover-img"/>
							<div class="pd10">
								<div class="ft12 ftw400 cl-black text-over4">{{item.abbreviation}}</div>
								<div class="flex alcenter space mt10" >
									<div class="cl-price ft14">￥{{item.market_price}}</div>
									<div class="choose-button" v-if="item.is_select==1">选择</div>
									<div class="choose-button" v-else  @click="chooseIt(index)">选择</div>
								</div>
							</div>
							<div class="service-data-item-choose flex alcenter center" v-if="item.is_select==1">
								<i class="iconfont iconyes ft28 cl-green"/>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	export default{
		mixins:[listMixin],
		data(){
			return{
				loading:false,
				goods:{},
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search:{
					abbreviation:'',
				},
				datas:[
					{
						project_id:1,
						abbreviation:'全身SPA理疗',
						cover_img:"https://img.woyaou.com/692d421d7911700b507dff6233a34be01618278424.jpg",
						market_price:'298.00',
					},
					{
						project_id:2,
						abbreviation:'半永久纹眉',
						cover_img:"https://img.woyaou.com/ed8eab4c74edaa437887664f947d54621618278471.jpg",
						market_price:'300.00',
					},
				],
			}
		},
		created() {
			
		},
		computed:{
			getDatas(){
				let datas =JSON.parse(JSON.stringify(this.datas));
				for(var i in datas){
					for(var j in this.cashierCart){
						if(this.cashierCart[j].type=='service'){
							if(this.cashierCart[j].project_id==datas[i].project_id){
								datas[i].is_select=1;
							}
						}
					}
				}
				return datas;
			}
		},
		methods:{
			chooseIt(index){
				let cart=JSON.parse(JSON.stringify(this.cashierCart));
				for(var i in cart){
					if(cart[i].type=='service'){
						if(this.datas[index].project_id == cart[i].project_id){
							return false;
						}
					}
				}
				
				cart.push({
					project_id:this.getDatas[index].project_id,
					num:1,
					type:'service',
					name:this.getDatas[index].abbreviation,
					cover_img:this.getDatas[index].cover_img,
					market_price:this.getDatas[index].market_price,
				});
				this.$store.commit('setCashierCart',cart);
			},
		},
		 
	}
</script>

<style>
	.service-data-item{
		width: 24%;
		height: 250px;
		margin-right: 1%;
		margin-bottom: 1%;
		border: 1px solid #F4F5F7;
		box-shadow:0px 15px 10px -15px #000;
		line-height: 1;
		position: relative;
	}
	
	.service-data-item.active{
		border: 1px solid #00C657;
	}
	
	.service-data-item-choose{
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 180px;
	}
	.service-data-item-quota{
		position: absolute;
		top:0;
		right: 0;
		padding: 4px 8px;
		background: #F56C6C;
		color: #FFFFFF;
		border-radius: 4px;
	}
	
	.service-data-item-cover-img{
		width: 100%;
		height: 180px;
	}
	
	.choose-button{
		padding: 8px 14px;
		background: #4772FF;
		color: #ffffff;
		border-radius: 4px;
	}
</style>
