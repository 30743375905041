<template>
	<div>
        <div class="search" >
            <div style="display: flex; align-items: center;">
                <!-- <div  class="tabs_selected">
                    <div  class="tabs_item " :class="type == 1?'tabs_item-active':''" @click="changeType(1)">套卡</div>
                    <div class="tabs_item " :class="type == 2?'tabs_item-active':''" @click="changeType(2)">卡项</div>
                </div> -->
                <div style="display: flex;align-items:center;">
                    <a-input placeholder="查询套餐名称" v-model="xmsearch.name" class="search-input" :allowClear="true"></a-input>
                    <a-button @click="searchXmAct" type="primary" :loading="xmsearch.loading">查询</a-button>
                </div>
            </div>
        </div>
        <div class="type-container">
            <!-- <div class="sort-box" v-if="type == 2">
                <div style="height: 10px;"></div>
                <div class="sort-box-1234">
                    <div class="scroll-view">
                        <div class="sort-list" style="transform: translateX(0px);">
                            <div  selected="selected" class="sort-item">全部分类</div>
                            <div class="sort-item" v-for="(item,index) in 50">古法推拿{{ index }}</div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="goods-list-scroll">
                <div  class="goods-list-tk">
                    <div class="goods-item"  v-for="(item,index) in list">
                        <div  class="flex-center goods_img_box img">
                            
                            <img :src="item.cover_img" v-if="item.cover_img" height="100%" width="100%">
                            <img src="../../../../../src/assets/image_new/default_goods_img.png" v-else height="100%" width="100%">
                        </div>
                        <div class="goods-info">
                            <div  class="name text-line2">{{item.name}}</div>
                            <div class="price">￥{{ item.price }}
                                <span class="btn-details" v-if="type == 1" @click="showDetail(index)">详情</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
        <a-modal :title="visibleTitle+'-套餐详情'" :width="810" :visible="visible"  @cancel="handleCancel" >
            <template slot="footer" >
               
                <a-button  @click="handleCancel">
                    取消
                </a-button>
                <a-button  @click="relationProjectVisible = true" type="primary">
                    赠送商品
                </a-button>
                <a-button  @click="relationProjectVisibleXm = true" type="primary">
                    赠送项目
                </a-button>
                <a-button  @click="confirmTaocan" type="primary" :loading="confirmLoading">
                    确认套餐
                </a-button>
            </template>
            <div class="bodyBox2">
                <div  class="top_count">以下 <span class="red">{{showItemList.length}}</span> 个产品项目，  共可累计消费<span class="red">{{ getShowItemListNum }}</span>次 </div>
                <div  class="list">
                    <div  class="item" v-for="(item,index) in showItemList">
                        <div  class="flex-center goods_img_box img">
                            <img :src="item.cover_img" v-if="item.cover_img"  height="120px" width="100%">
                            <img  src="../../../../../src/assets/image_new/default_goods_img.png"  v-else
                            height="120px" width="100%">
                        </div>
                        <div  class="item-detail">
                            <div class="name text-line2">{{ item.full_name }} </div>
                            <div class="price"> ￥{{ item.market_price }} <span class="gds_num" style="opacity: 1;">x {{ item.num }}</span></div>
                            <div class="gds_type">项目</div>
                        </div>
                    </div>
                    <div  class="item" v-for="(item,index) in defaultList">
                        <div  class="flex-center goods_img_box img">
                            <img :src="item.cover_img" v-if="item.cover_img"  height="120px" width="100%">
                            <img  src="../../../../../src/assets/image_new/default_goods_img.png"  v-else
                            height="120px" width="100%">
                        </div>
                        <div  class="item-detail">
                            <div class="name text-line2">{{ item.name }} </div>
                            <div class="price"> ￥{{ item.original_price }} <span class="gds_num" style="opacity: 1;">x {{ item.num }}</span></div>
                            <div class="gds_type">赠送-产品</div>
                            <div class="gds_type" @click="delZengsong(index,'goods')" style="background: red;margin-left: 52px;padding: 0 10px;">删除</div>
                        </div>
                    </div>
                    <div  class="item" v-for="(item,index) in defaultListXm">
                        <div  class="flex-center goods_img_box img">
                            <img :src="item.cover_img" v-if="item.cover_img"  height="120px" width="100%">
                            <img  src="../../../../../src/assets/image_new/default_goods_img.png"  v-else
                            height="120px" width="100%">
                        </div>
                        <div  class="item-detail">
                            <div class="name text-line2">{{ item.full_name }} </div>
                            <div class="price"> ￥{{ item.market_price }} <span class="gds_num" style="opacity: 1;">x {{ item.num }}</span></div>
                            <div class="gds_type">赠送-项目</div>
                            <div class="gds_type" @click="delZengsong(index,'xiangmu')" style="background: red;margin-left: 52px;padding: 0 10px;">删除</div>
                        </div>
                    </div>
                </div>
            </div>    
        </a-modal>     
        <div v-if="relationProjectVisible">
            <relation-xmgoods :defaultList="defaultList" :visible="relationProjectVisible" @cancel="cancelRelationProject" @ok="okRelationProject"></relation-xmgoods>
        </div>
        <div v-if="relationProjectVisibleXm">
            <relation-project :value="defaultListXm" :visible="relationProjectVisibleXm" @cancel="cancelRelationProject"
             @ok="okRelationProjectXm"></relation-project>
        </div>
    </div>

</template>

<script>
    import relationXmgoods from '../newindex/selXmOrGoods/modal/relationXmgoods.vue';
    import relationProject from '../newindex/selXmOrGoods/modal/relationProject.vue';
	export default{
        components:{
            relationXmgoods,relationProject //relationXmgoods,
        },
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				},
			}
		},
		computed:{
			getShowItemListNum(){
                var count = 0;
				this.showItemList.forEach(item=>{
                    var num = parseInt(item.num);
                    if(isNaN(num))
                    {
                        num = 0;
                    }
                    count = count + num;
                })
                return count;
			}
		},
		data(){
			return{
                visibleTitle:'',
                visible:false,
                confirmLoading:false,
				type:1,
                selIndex:-1,
                showItemList:[],
                list:[],
                xmpagination: {
					current: 1,
					pageSize: 200, //每页中显示10条数据
					total: 0,
				},
                xmsearch:{
					name:'',
                    loading:false,
				},
                defaultList:[],
				relationProjectVisible:false,
                defaultListXm:[],
                relationProjectVisibleXm:false,
			}
		},
        created(){
            this.getTaocanLists();
        },
		methods:{
            cancelRelationProject(){
				this.relationProjectVisible=false;
                this.relationProjectVisibleXm=false;
			},
			okRelationProjectXm(changeList){
                var info = this.list[this.selIndex];
                var once_card_id = info.once_card_id?info.once_card_id:0;
                if(once_card_id)
                {
                    var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                    if(!cashierCartTaocan.zsXmListObj)
                    {
                        cashierCartTaocan.zsXmListObj = {};
                    }
                    cashierCartTaocan.zsXmListObj[once_card_id] = JSON.parse(JSON.stringify(changeList));
                    this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
                    this.initDefaultList();
                }
				this.relationProjectVisibleXm=false;
				//this.getProject();
			},
            delZengsong(index,type){
                var info = this.list[this.selIndex];
                var once_card_id = info.once_card_id?info.once_card_id:0;
                
                if(once_card_id)
                {
                    var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                    if(type == 'goods')
                    {
                        if(!cashierCartTaocan.zsGoodsListObj)
                        {
                            cashierCartTaocan.zsGoodsListObj = {};
                        }
                        var list = cashierCartTaocan.zsGoodsListObj[once_card_id]?cashierCartTaocan.zsGoodsListObj[once_card_id]:[];
                        list.splice(index,1);
                        cashierCartTaocan.zsGoodsListObj[once_card_id] = JSON.parse(JSON.stringify(list));
                    }else if(type == 'xiangmu')
                    {
                        if(!cashierCartTaocan.zsXmListObj)
                        {
                            cashierCartTaocan.zsXmListObj = {};
                        }
                        var list = cashierCartTaocan.zsXmListObj[once_card_id]?cashierCartTaocan.zsXmListObj[once_card_id]:[];
                        list.splice(index,1);
                        cashierCartTaocan.zsXmListObj[once_card_id] = JSON.parse(JSON.stringify(list));
                    }
                    this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
                    this.initDefaultList();
                }
            },
            initDefaultList(){
                var info = this.list[this.selIndex];
                
                var once_card_id = info.once_card_id?info.once_card_id:0;
                if(once_card_id)
                {
                    //处理赠送的产品
                    var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                    if(!cashierCartTaocan.zsGoodsListObj)
                    {
                        cashierCartTaocan.zsGoodsListObj = {};
                    }
                    var list = cashierCartTaocan.zsGoodsListObj[once_card_id]?cashierCartTaocan.zsGoodsListObj[once_card_id]:[];
                    this.defaultList = JSON.parse(JSON.stringify(list));
                    //处理赠送的项目
                    if(!cashierCartTaocan.zsXmListObj)
                    {
                        cashierCartTaocan.zsXmListObj = {};
                    }
                    var list = cashierCartTaocan.zsXmListObj[once_card_id]?cashierCartTaocan.zsXmListObj[once_card_id]:[];
                    this.defaultListXm = JSON.parse(JSON.stringify(list));
                }
            },
			okRelationProject(changeList){
                var info = this.list[this.selIndex];
                var once_card_id = info.once_card_id?info.once_card_id:0;
                if(once_card_id)
                {
                    var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                    if(!cashierCartTaocan.zsGoodsListObj)
                    {
                        cashierCartTaocan.zsGoodsListObj = {};
                    }
                    cashierCartTaocan.zsGoodsListObj[once_card_id] = JSON.parse(JSON.stringify(changeList));
                    this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
                    this.initDefaultList();
                }
				this.relationProjectVisible=false;
				//this.getProject();
			},
            searchXmAct(){
                this.xmsearch.loading = true;
				this.xmpagination.current = 1;
				this.getTaocanLists();
			},
            getTaocanLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('cardshow',{
					limit:this.xmpagination.pageSize,
					page:this.xmpagination.current,
					name:this.xmsearch.name,
				}).then(res=>{
					this.xmpagination.total=res.total;
					this.list=res.list;
					
					this.loading=false;
                    this.xmsearch.loading = false;
				}).catch(res=>{
					this.loading=false;
                    this.xmsearch.loading = false;
				})
			},
            handleCancel(){
				this.visible = false;
			},
            showDetail(index){
                this.selIndex = index;
                var info = this.list[index];
                this.visibleTitle = info.name;
                this.showItemList = info.card_info;
                this.initDefaultList();
                this.visible = true;
            },
            confirmTaocan(){
                var info = this.list[this.selIndex];
                var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                if(cashierCartTaocan.type == 1)
                {
                    //购买套餐
                    var cashierCartTaocanList =  cashierCartTaocan.list? cashierCartTaocan.list:[];
                    var needAppend = true;
                    cashierCartTaocanList.forEach((item,index) =>{
                        if(item.goods_id == info.once_card_id)
                        {
                            item.num = item.num+1;
                            needAppend = false;
                        }
                    })
                    if(needAppend)
                    {
                        var obj = {
                            goods_id:info.once_card_id,
                            num:1,
                            type:'taocan',
                            name:info.name,
                            cover_img:info.cover_img,
                            original_price:info.price,
                            vip_price:info.price,
                            sku_id:0,
                            quota:9999999999,
                            is_vip_discount: 0,
                        }
                        cashierCartTaocanList.push(obj);
                    }
                    cashierCartTaocan.list = cashierCartTaocanList;
					// console.log('cart',cart);
					// return ;
					this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
                    this.opeCashierCartTaocanTcUserPrice(); //调用common/mixin/common.js下的重置价格计算方法
                    this.visible = false;
                }
            },
            changeType(type){
                this.type = type;
            },
			
		}
	}
</script>

<style>
.bodyBox2 {
    
    width: calc(100% + 7px);
    padding-right: 7px;
    margin-right: -7px;
}
.top_count {
    font-size: 14px;
    color: #515a6e;
    margin-bottom: 20px;
}
.top_count .red{
    color: #ed4014;
    margin: 0 2px;
}
.bodyBox2 .list{
    display: flex;
    flex-wrap: wrap;
    width: 780px;
    max-height: 400px;
    overflow-y: auto;
}
.bodyBox2 .list .item {
    flex: none;
    width: 168px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 3px;
    padding-bottom: 16px;
    border-radius: 8px;
    border: 1px solid #dcdee2;
    box-shadow: 0 1px 8px 0 rgb(81 90 110 / 12%);
}


.bodyBox2 .list .item .name{
    font-size: 14px;
    font-weight: 800;
    color: #17233d;
    line-height: 1.5;
    height: 3em;
}

.bodyBox2 .list .item .gds_type{
    display: inline-block;
    height: 16px;
    line-height: 16px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background: #f90;
    border-radius: 2px;
}

.goods_img_box {
    position: relative;
    background-color: #f8f8f9;
    flex: none;
    overflow: hidden;
}


.goods-list-scroll{
    flex: 1;
    overflow-y: auto;
    height: calc(100vh - 240px);
    position: relative;
}
.goods-list-tk {
    min-height: calc(100%);
    padding: 0 20px;
    padding-right: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}
.goods-list-tk .goods-item{
    margin-right: 10px;
    margin-bottom: 10px;
    height: 94px;
    width: calc(25% - 10px);
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: flex;
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.goods-list-tk .goods-item {
    width: calc(25% - 10px);
}

@media screen and (max-width: 1600px){
    .goods-list-tk .goods-item {
        width: calc(25% - 10px);
        }
}
@media screen and (max-width: 1400px){
    .goods-list-tk .goods-item {
        width: calc(33.3% - 10px);
    }

}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.goods_img_box {
    position: relative;
    background-color: #f8f8f9;
    flex: none;
    overflow: hidden;
}

.goods-list-tk .goods-item .img{
    width: 80px;
    height: 80px;
    border-radius: 4px;
}

.goods-list-tk .goods-item .goods-info {
    margin-left: 10px;
    font-size: 14px;
    height: 80px;
    padding-top: 3px;
    width: 100%;
}
.text-line1, .text-line2 {
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-line2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}
.goods-list-tk .goods-item .goods-info .name{
    height: 4.3em;
    line-height: 1.15;
}

.goods-list-tk .goods-item .goods-info .num {
    font-size: 12px;
    color: #808695;
    margin-top: 7px;
}

.goods-list-tk .goods-item .goods-info .price {
    color: #17233d;
    margin-top: 3px;
    line-height: 1;
    font-weight: 700;
}
.goods-list-tk .goods-item .goods-info .price  .btn-details{   
     float: right;
    color: #5cadff;
    cursor: pointer;
    text-align: right;
    margin-bottom: -2px;
    font-weight: normal;
   
}

.goods-list-tk .goods-item:hover, .goods-list-tk .goods-item[selected] {
    background: #e5f6ff;
    border-color: #2d8cf0;
}

.type-container{
    flex: 1;
    display: flex;
}
.type-container .sort-box{
    height: calc(100vh - 230px);
    border-right: 1px solid #ebebeb;
    overflow-y: auto;
}
.sort-box-1234{
    font-size: 14px;
    white-space: nowrap;
}
.sort-box-1234 .scroll-view {
    overflow: hidden;
}

.sort-box-1234 .sort-list{
    transition: transform .3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.sort-box-1234 .sort-item{
    width: 130px;
    height: 30px;
    line-height: 30px;
    margin-bottom: 15px;
    text-align: center;
    cursor: pointer;
}
.sort-box-1234 .sort-item:hover, .sort-box-1234 .sort-item[selected]{
    background: #2d8cf0;
    color: #fff;
    font-weight: 700;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}


.search{
    padding: 20px 20px 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 10px;
}	
.search .tabs_selected {
    display: flex;
    margin: 0px 10px 0px 0;
    background: #e5f6ff;
    border-radius: 20px;
}

.search .tabs_selected .tabs_item{
    width: 75px;
    height: 35px;
    line-height: 35px;
    background: #e5f6ff;
    color: #2d8cf0;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}
.search .tabs_selected .tabs_item-active{
    background: #2d8cf0;
    color: #fff;
}
.search .search-input {
    position: relative;
    width: 250px;
    margin: 0px 10px 00px 0;
}
</style>
