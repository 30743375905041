<template>
	<div >
		<a-modal title="选择提成人" :width="1200" :visible="showSelUserStatus" :maskClosable="false" @cancel="handleCancel" >
            <template slot="footer" >
                <a-button  key="back"  @click="handleCancel">
                    取消
                </a-button>
                <a-button  key="submit" type="primary" @click="confirmTcUserFunc" :loading="confirmLoading">
                    确定
                </a-button>
            </template>
            <div class="">
                <!-- <div class="order_box">
                    <div class="stitle">
                        <a class="close" :class="type == 1?'open':''" href="javascript:;" @click="changeType(1)">选择提成人</a> 
                        <a class="close" :class="type == 2?'open':''" href="javascript:;" @click="changeType(2)">折扣/改价</a>
                    </div>
                </div> -->
                <div  class="award-main tabs-container-item" v-if="type == 1">
                    <div class="left-box"> 
                        <!-- <div class="search">
                            <a-select style="width: 80px;" placeholder="请选择" size="small">
                                <a-select-option :value="0" :title="'全部状态全部状态'">全部状态全部状态</a-select-option>
								<a-select-option :value="1">待确认</a-select-option>
							</a-select>
                            <a-input placeholder="员工姓名/账号" class="IviewInput" size="small"></a-input>
                            <a-button type="primary" size="small">查询</a-button>
                        </div> -->
                        <div class="job-and-user">
                            <div class="job-list">
                                <div class="name" :class="selPositionId == 0?'name-active':''"  @click="changePosition(0)">全部</div>
                                <div  class="name"  :class="selPositionId == item.id?'name-active':''"
                                     v-for="(item,index) in  zcList" @click="changePosition(item.id)">{{ item.name }}</div>
                            </div>
                            <div class="user-list">
                                <a-checkbox-group @change="checkBoxChange" :value="selTcUserIds">
                                <a-checkbox class="user-item"  :value="item.technician_id" v-show="item.is_show == 1"
                                        v-for="(item, index) in showUserList" :key="index" >
                                        {{ item.name }}
                                    </a-checkbox>
                                </a-checkbox-group>
                                <!-- 
                                    
                                </a-checkbox-group> -->
                            </div>
                        </div>
                    </div> 
                    <div class="right-box">
                        <div  class="user-award-box-header">
                            <table width="100%" cellspacing="0" cellpadding="0" style="text-align: center;">
                                <colgroup >
                                    <col style="width: 17%;"><col style="width: 12%;">
                                    <col style="width: 17%;"><col style="width: 17%;">
                                    <col style="width: 20%;">
                                    <col style="width: 17%;">
                                </colgroup>
                                <tr>
                                    <th >姓名/职称</th>
                                    <!-- <th >指定</th> -->
                                    <th >类型</th>
                                    <th >分摊%</th>
                                    <th >分摊金额</th>
                                    <th >提成值</th>
                                    <th  style="white-space: nowrap;">提成金额 
                                        <!-- <div  class="ivu-tooltip">
                                            <div class="ivu-tooltip-rel">
                                                <span  class="icon-issue">?</span>
                                            </div>
                                        </div>-->
                                    </th>
                                </tr>
                            </table>
                        </div>
                        <div class="user-award-box">
                            <table width="100%" cellspacing="0" cellpadding="0" style="text-align: center;">
                                <colgroup >
                                    <col style="width: 17%;"><col style="width: 12%;">
                                    <col style="width: 17%;"><col style="width: 17%;">
                                    <col style="width: 20%;">
                                    <col style="width: 17%;">
                                </colgroup>
                                <tbody v-for="(item,index) in fcUserList" style="border: 1px solid #d7dde4;">
                                    <tr bdt>
                                    <td rowspan="2">
                                        <div  class="user-name">{{ item.name }} <!----></div>
                                        <div  class="position_name"> {{item.position_id_text?item.position_id_text:'无职称'}} </div>
                                    </td>
                                    <!-- <td rowspan="2">
                                        <a-switch></a-switch>
                                    </td> -->
                                    <td  v-if="item.yejiticheng">
                                        业绩提成
                                        <!-- <a-checkbox-group >
                                            <a-checkbox class="user-item" :value="'0'" size="small">
                                                业绩提成
                                            </a-checkbox>
                                        </a-checkbox-group> -->
                                    </td>
                                    <td  v-if="item.yejiticheng">
                                        <a-input-number class="w70" @change="priceChange(index,'yejiticheng','partner_rate')"  :precision="0" 
                                         v-model="item.yejiticheng.partner_rate" :min="0" :max="100" size="small"></a-input-number>%
                                    </td>
                                    <td  v-if="item.yejiticheng">
                                        <a-input-number class="w70" @change="priceChange(index,'yejiticheng','partner_amount')"
                                        :precision="2" v-model="item.yejiticheng.partner_amount" :min="0" :max="item.price * item.num"></a-input-number>元
                                    </td>
                                    <td  v-if="item.yejiticheng">
                                        <a-input-number class="w70" @change="priceChange(index,'yejiticheng','val')"
                                         :precision="2" v-model="item.yejiticheng.val" :min="0" size="small" ></a-input-number>
                                        <a-select style="width: 50px;"  v-model="item.yejiticheng.award_type" size="small" 
                                        @change="priceChange(index,'yejiticheng','award_type')"
                                        >
                                            <a-select-option :value="1" >元</a-select-option>
                                            <a-select-option :value="2">%</a-select-option>
                                        </a-select>
                                    </td>
                                    <td  v-if="item.yejiticheng">
                                        <div class="w70  tichengjine"  size="small">{{ item.yejiticheng.ticheng_amount }} 元</div>
                                    </td>
                                </tr>
                                <tr bdt v-if="item.shougongfei">
                                  
                                    <td>
                                        手工费提成
                                        <!-- <a-checkbox-group size="small">
                                            <a-checkbox class="user-item" :value="'0'">
                                                手工费提成
                                            </a-checkbox>
                                        </a-checkbox-group> -->
                                    </td>
                                    <td>
                                        <a-input-number class="w70" @change="priceChange(index,'shougongfei','partner_rate')"
                                         :precision="0"  v-model="item.shougongfei.partner_rate" :min="0" :max="100" size="small"></a-input-number>%
                                    </td>
                                    <td>
                                        <a-input-number class="w70" @change="priceChange(index,'shougongfei','partner_amount')"
                                        :precision="2" v-model="item.shougongfei.partner_amount" :min="0" :max="item.price * item.num"></a-input-number>元
                                    </td>
                                    <td>
                                        <a-input-number class="w70" @change="priceChange(index,'shougongfei','val')"
                                        :precision="2" v-model="item.shougongfei.val" :min="0" size="small" ></a-input-number>
                                        <a-select style="width: 50px;"  @change="priceChange(index,'shougongfei','award_type')" v-model="item.shougongfei.award_type" size="small">
                                            <a-select-option :value="1" >元</a-select-option>
                                            <a-select-option :value="2">%</a-select-option>
                                        </a-select>
                                    </td>
                                    <td>
                                        <div class="w70  tichengjine"  size="small">{{ item.shougongfei.ticheng_amount }} 元</div>
                                       
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="tbzd" style="display:flex;align-items: center;">
                                <a-checkbox-group @change="tongbuzhengdanFunc">
                                    <a-checkbox class="user-item" :value="1" size="small">
                                        同步整单
                                    </a-checkbox>
                                </a-checkbox-group>
                                <div style="font-size: 12px;color:#999999;font-weight: normal;">(将该次设置同步到购物车中相同类型下的提成人数据，已设置过的数据将被覆盖)</div>
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="tabs-container-item flex-center" v-if="type==2">
                    <div class="disc-box">
                        <a-form layout="horizontal">
                            <a-form-item label="折扣率" required="true" class="gaijia-form-item">
                                <a-input-number ></a-input-number>
                            </a-form-item>

                            <a-form-item label="零售价" required="true" class="gaijia-form-item">
                                <a-input-number ></a-input-number>
                            </a-form-item>
                            <a-form-item label="数量" required="true" class="gaijia-form-item">
                                <a-input-number :min="1"></a-input-number>
                            </a-form-item>
                        </a-form>
                        <div class="keyboard-container">
                            <div class="keyboard-box">
                                <div class="k-left">
                                    <button class="key-item num">1</button>
                                    <button class="key-item num">2</button>
                                    <button class="key-item num">3</button>
                                    <button class="key-item num">4</button>
                                    <button class="key-item num">5</button>
                                    <button class="key-item num">6</button>
                                    <button class="key-item num">7</button>
                                    <button class="key-item num">8</button>
                                    <button class="key-item num">9</button>
                                    <button class="key-item point">.</button>
                                    <button class="key-item num">0</button>
                                    <button  class="key-item">
                                        <img src="../../../../../src/assets/image_new/keyboard_del.png" alt="delete" height="20">
                                    </button>
                                </div>
                                <div class="k-right">
                                    <button class="btn-item btn-rewrite">重输</button>
                                    <button class="btn-item btn-confirm blue" style="display: flex;"> 确认 </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>  
        </a-modal>   
	</div>
</template>

<script>
	export default{
		props:{
			showSelUserStatus:{
				type:Boolean,
				default:false,
			},
            initInfo:{
				type:Object,
				default:{},
			},
		},
		computed:{
			
		},
        created(){
            var cacheKey = 'cash_goods_set_common_local_cache';
            var cacheTcSet =  localStorage.getItem(cacheKey);
			cacheTcSet = cacheTcSet?JSON.parse(cacheTcSet):{};
            this.tcSet  = cacheTcSet.perinfo?cacheTcSet.perinfo:{};
            this.getZcList();
            var goodsInfo = {};
            console.log('this.initInfo',this.initInfo)
            //恢复默认数据
            switch(this.initInfo.type)
            {
                case 'cashierCart':
                    //项目/产品
                    var cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
                    var goodsInfo = cashierCart[this.initInfo.index]; 
                    console.log('goodsInfo',goodsInfo)

                    break;
                case 'cashierCartTaocan':
                    //套餐
                    var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                    var goodsInfo = cashierCartTaocan.list[this.initInfo.index];
                    break;
                case 'cashierCartRecharge':
                    var cashierCartRecharge = JSON.parse(JSON.stringify(this.cashierCartRecharge));
                    var goodsInfo = cashierCartRecharge;
                    break;
            }
            if(goodsInfo.fcUserList && goodsInfo.fcUserList.length > 0)
            {
                var selTcUserIds = [],showUserList = [];
                goodsInfo.fcUserList.forEach((item,index)=>{
                    selTcUserIds.push(item.technician_id);
                })
                this.selTcUserIds = selTcUserIds;
                this.fcUserList = JSON.parse(JSON.stringify(goodsInfo.fcUserList));
            }
            
        },
		data(){
			return{
                tongbuzhengdan:[],
				type:1,
                confirmLoading:false,
                fcUserList:[],
                tcSet:{},
                zcList:[],
                zcListByPid:{},
                allUserList:[],
                userListByTid:{},
                showUserList:[],
                selPositionId:0,
                selTcUserIds:[],
                
			}
		},
		methods:{
            tongbuzhengdanFunc(e){
                this.tongbuzhengdan = e;
            },
            confirmTcUserFunc(){
                var fcUserList = JSON.parse(JSON.stringify(this.fcUserList));
                this.confirmLoading = true;
                if(this.initInfo.type == 'cashierCart')
                {
                    var cashierCart = JSON.parse(JSON.stringify(this.cashierCart));
                    var goodsInfo = cashierCart[this.initInfo.index]; 
                    cashierCart[this.initInfo.index].fcUserList = fcUserList;
                    if(this.tongbuzhengdan.indexOf(1) > -1)
                    {
                        //同步整单
                        cashierCart.forEach((item,index)=>{
                            if(index == this.initInfo.index)
                            {
                                //相同不处理
                            }else{
                                if(item.type == goodsInfo.type)
                                {
                                    
                                    var nowFcUserList = JSON.parse(JSON.stringify(fcUserList));
                                    nowFcUserList.forEach((now_item,now_index)=>{
                                        now_item.price = parseFloat(item.original_price);
                                        now_item.num = parseInt(item.num);
                                        var yejiticheng = now_item.yejiticheng?now_item.yejiticheng:'';
                                        if(yejiticheng)
                                        {
                                            yejiticheng.partner_amount  = now_item.price * now_item.num * yejiticheng.partner_rate / 100;
                                            //console.log('item.price * item.num * yejiticheng.partner_rate',item.price ,item.num ,yejiticheng.partner_rate)
                                            //return ;
                                            if(yejiticheng.award_type == 1)
                                            {
                                                //固定金额
                                                yejiticheng.ticheng_amount = (parseFloat((yejiticheng.partner_rate /100) * yejiticheng.val * now_item.num).toFixed(2));
                                            }else{
                                                yejiticheng.ticheng_amount = parseFloat(parseFloat(yejiticheng.partner_amount * yejiticheng.val / 100).toFixed(2));
                                            }
                                            now_item.yejiticheng = yejiticheng;
                                        }
                                        var shougongfei = now_item.shougongfei?now_item.shougongfei:'';
                                        console.log(shougongfei,'手工费')
                                        if(shougongfei)
                                        {
                                            shougongfei.partner_amount  = now_item.price * now_item.num * shougongfei.partner_rate / 100;
                                            if(shougongfei.award_type == 1)
                                            {
                                                //固定金额
                                                shougongfei.ticheng_amount = (parseFloat((shougongfei.partner_rate /100) * shougongfei.val * now_item.num).toFixed(2));
                                            }else{
                                                shougongfei.ticheng_amount = parseFloat(parseFloat(shougongfei.partner_amount * shougongfei.val / 100).toFixed(2));
                                            }
                                            now_item.shougongfei = shougongfei;
                                        }
                                    })
                                    item.fcUserList = nowFcUserList;
                                }
                            }
                            
                        }) 
                    }
                    this.$store.commit('setCashierCart',cashierCart);
                }else if(this.initInfo.type == 'cashierCartTaocan')
                {
                    var cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
                    var goodsInfo = cashierCartTaocan.list[this.initInfo.index]; 
                    cashierCartTaocan.list[this.initInfo.index].fcUserList = fcUserList;
                    if(this.tongbuzhengdan.indexOf(1) > -1)
                    {
                        //同步整单
                        cashierCartTaocan.list.forEach((item,index)=>{
                            if(index == this.initInfo.index)
                            {
                                //相同不处理
                            }else{
                                if(item.type == goodsInfo.type)
                                {
                                    
                                    var nowFcUserList = JSON.parse(JSON.stringify(fcUserList));
                                    nowFcUserList.forEach((now_item,now_index)=>{
                                        now_item.price = parseFloat(item.original_price);
                                        now_item.num = parseInt(item.num);
                                        var yejiticheng = now_item.yejiticheng?now_item.yejiticheng:'';
                                        if(yejiticheng)
                                        {
                                            yejiticheng.partner_amount  = now_item.price * now_item.num * yejiticheng.partner_rate / 100;
                                            //console.log('item.price * item.num * yejiticheng.partner_rate',item.price ,item.num ,yejiticheng.partner_rate)
                                            //return ;
                                            if(yejiticheng.award_type == 1)
                                            {
                                                //固定金额
                                                yejiticheng.ticheng_amount = (parseFloat((yejiticheng.partner_rate /100) * yejiticheng.val * now_item.num).toFixed(2));
                                            }else{
                                                yejiticheng.ticheng_amount = parseFloat(parseFloat(yejiticheng.partner_amount * yejiticheng.val / 100).toFixed(2));
                                            }
                                            now_item.yejiticheng = yejiticheng;
                                        }
                                    })
                                    item.fcUserList = nowFcUserList;
                                }
                            }
                        }) 
                    }
                    this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
                }else if(this.initInfo.type == 'cashierCartRecharge')
                {
                    var cashierCartRecharge = JSON.parse(JSON.stringify(this.cashierCartRecharge));
                    cashierCartRecharge.fcUserList = fcUserList;
                    this.$store.commit('setCashierCartRecharge',cashierCartRecharge);
                }
                this.confirmLoading = false;
                this.$emit('cancel'); //关闭弹窗
            },
            priceChange(index,type,key){
                var item = this.fcUserList[index];
                var info = item[type];
                var val = info[key];
                if(val == '' || val == null)
                {
                    if(key == 'partner_rate')
                    {
                        info.partner_rate  = 0;
                        info.partner_amount  = 0;
                        info.ticheng_amount = 0;
                    }else if(key == 'partner_amount')
                    {
                        info.partner_rate  = 0;
                        info.partner_amount  = 0;
                        info.ticheng_amount = 0;
                    }else if(key == 'val')
                    {
                        info.val = 0;
                        info.ticheng_amount = 0;
                    }
                    console.log('info',info)
                    this.fcUserList[index][type] = info;
                    return ;
                }
                val = parseFloat(val);
                if(isNaN(val))
                {
                    return ;
                }
                if(key == 'partner_rate')
                {
                    info.partner_amount  = item.price * item.num * info.partner_rate / 100;
                }else if(key == 'partner_amount')
                {
                    //console.log('item.price * item.num / this.fcUserList.length',info.partner_amount / (item.price * item.num))
                    info.partner_rate  = parseFloat(parseFloat(info.partner_amount /(item.price * item.num ) * 100).toFixed(2));
                }
                if(info.award_type == 1)
                {
                    //固定金额
                    info.ticheng_amount = (parseFloat((info.partner_rate /100) * info.val * item.num).toFixed(2));
                }else{
                    info.ticheng_amount = parseFloat(parseFloat(info.partner_amount * info.val / 100).toFixed(2));
                }
                this.fcUserList[index][type] = info;
                console.log(index,type,key,val);
            },
            checkBoxChange(nowSelIds){
                console.log(nowSelIds)
                this.selTcUserIds = nowSelIds;
                var userListByTid = this.userListByTid;
                var tcSet = this.tcSet;
                console.log(userListByTid)
                var zcListByPid = this.zcListByPid;
                console.log(zcListByPid)

                var fcUserList = [];
                nowSelIds.forEach((item,index)=>{
                    var userInfo = this.userListByTid[item];
                    var obj = {
                        technician_id:userInfo.technician_id,
                        position_id:userInfo.position_id?userInfo.position_id:0,
                        name:userInfo.name,
                        position_id_text:zcListByPid[userInfo.position_id]?zcListByPid[userInfo.position_id].name:'',
                    }
                    console.log('this.initInfo.type',this.initInfo.type)
                    if(this.initInfo.type == 'cashierCart')
                    {
                        //选择的是项目或者产品
                        var goodsInfo = this.cashierCart[this.initInfo.index]; 
                        obj.price = parseFloat(goodsInfo.original_price);
                        obj.num = parseInt(goodsInfo.num);
                        obj.goods_type = goodsInfo.type;
                        if(goodsInfo.type == 'goods' || goodsInfo.type == 'xiangmu')
                        {
                            if(goodsInfo.is_zs && goodsInfo.is_zs == 1)
                            {
                                //赠送的不参与业绩提成
                                obj.yejiticheng = '';
                            }else{
                                if(goodsInfo.type == 'goods')
                                {
                                    var chanpinTc = tcSet.chanpin?tcSet.chanpin:{};
                                }else{
                                    var chanpinTc = tcSet.xiangmu?tcSet.xiangmu:{};
                                }
                                obj.yejiticheng = {
                                    partner_rate:parseFloat(parseFloat(100/nowSelIds.length).toFixed(2)),
                                    val:(chanpinTc[obj.position_id] && chanpinTc[obj.position_id][goodsInfo.goods_id])?
                                        parseFloat(chanpinTc[obj.position_id][goodsInfo.goods_id].val):0,
                                    award_type: (chanpinTc[obj.position_id] && chanpinTc[obj.position_id][goodsInfo.goods_id])?
                                        parseFloat(chanpinTc[obj.position_id][goodsInfo.goods_id].award_type):1,   
                                }
                                obj.yejiticheng.partner_amount  = obj.price * obj.num *  (obj.yejiticheng.partner_rate / 100);
                                if(obj.yejiticheng.val == '' || obj.yejiticheng.award_type == '')
                                {
                                    obj.yejiticheng.ticheng_amount = '';
                                }else{
                                    //业绩、手工费设置提成值为固定时，提成金额=分摊%x提成数值x数量
                                    if(obj.yejiticheng.award_type == 1)
                                    {
                                        //固定金额
                                        obj.yejiticheng.ticheng_amount = (obj.yejiticheng.partner_rate /100) * obj.yejiticheng.val * obj.num;
                                    }else{
                                        obj.yejiticheng.ticheng_amount = obj.yejiticheng.partner_amount * obj.yejiticheng.val / 100;
                                    }
                                }
                            }
                            
                        }
                        obj.shougongfei = '';
                        if(goodsInfo.type == 'xiangmu')
                        {
                            var shougongfeiTc = tcSet.shougongfei?tcSet.shougongfei:{};
                            console.log('shougongfeiTc',shougongfeiTc)
                            console.log('obj',obj)

                            obj.shougongfei = {
                                partner_rate:parseFloat(100/nowSelIds.length).toFixed(2),
                                val:(shougongfeiTc[obj.position_id] && shougongfeiTc[obj.position_id][goodsInfo.goods_id])?
                                    parseFloat(shougongfeiTc[obj.position_id][goodsInfo.goods_id].val):0,
                                award_type: (shougongfeiTc[obj.position_id] && shougongfeiTc[obj.position_id][goodsInfo.goods_id])?
                                    parseFloat(shougongfeiTc[obj.position_id][goodsInfo.goods_id].award_type):1,   
                            }
                            obj.shougongfei.partner_amount  = obj.price * obj.num *  (obj.shougongfei.partner_rate / 100);
                            if(obj.shougongfei.val == '' || obj.shougongfei.award_type == '')
                            {
                                obj.shougongfei.ticheng_amount = '';
                            }else{
                                //业绩、手工费设置提成值为固定时，提成金额=分摊%x提成数值x数量
                                if(obj.shougongfei.award_type == 1)
                                {
                                    //固定金额
                                    obj.shougongfei.ticheng_amount = (obj.shougongfei.partner_rate /100) * obj.shougongfei.val * obj.num;
                                }else{
                                    obj.shougongfei.ticheng_amount = obj.shougongfei.partner_amount * obj.shougongfei.val / 100;
                                }
                            }
                        }
                        
                    }else if(this.initInfo.type == 'cashierCartTaocan')
                    {
                        //选择的是项目或者产品
                        var goodsInfo = this.cashierCartTaocan.list[this.initInfo.index]; 
                        obj.price = parseFloat(goodsInfo.original_price);
                        obj.num = parseInt(goodsInfo.num);
                        obj.goods_type = goodsInfo.type;
                        if(goodsInfo.type == 'taocan')
                        {
                            var taocanTc = tcSet.taocan?tcSet.taocan:{};
                            obj.yejiticheng = {
                                partner_rate:parseFloat(parseFloat(100/nowSelIds.length).toFixed(2)),
                                val:(taocanTc[obj.position_id] && taocanTc[obj.position_id][goodsInfo.goods_id])?
                                    parseFloat(taocanTc[obj.position_id][goodsInfo.goods_id].val):0,
                                award_type: (taocanTc[obj.position_id] && taocanTc[obj.position_id][goodsInfo.goods_id])?
                                    parseFloat(taocanTc[obj.position_id][goodsInfo.goods_id].award_type):1,   
                            }
                            obj.yejiticheng.partner_amount  = obj.price * obj.num *  (obj.yejiticheng.partner_rate / 100);
                            if(obj.yejiticheng.val == '' || obj.yejiticheng.award_type == '')
                            {
                                obj.yejiticheng.ticheng_amount = '';
                            }else{
                                //业绩、手工费设置提成值为固定时，提成金额=分摊%x提成数值x数量
                                if(obj.yejiticheng.award_type == 1)
                                {
                                    //固定金额
                                    obj.yejiticheng.ticheng_amount = (obj.yejiticheng.partner_rate /100) * obj.yejiticheng.val * obj.num;
                                }else{
                                    obj.yejiticheng.ticheng_amount = obj.yejiticheng.partner_amount * obj.yejiticheng.val / 100;
                                }
                            }
                        }
                        obj.shougongfei = '';
                        
                    }else if(this.initInfo.type == 'cashierCartRecharge')
                    {
                        var cashierCartRecharge = JSON.parse(JSON.stringify(this.cashierCartRecharge));
                        var goodsInfo = cashierCartRecharge; 
                        obj.price = parseFloat(goodsInfo.recharge_price);
                        obj.num = 1;
                        obj.goods_type = 'chuzhika';
                        if(obj.goods_type == 'chuzhika')
                        {
                            var chongzhiTc = tcSet.chongzhi?tcSet.chongzhi:{};
                            console.log('chongzhiTc',chongzhiTc)
                            obj.yejiticheng = {
                                partner_rate:parseFloat(parseFloat(100/nowSelIds.length).toFixed(2)),
                                val:(chongzhiTc[obj.position_id])? parseFloat(chongzhiTc[obj.position_id].val):0,
                                award_type: (chongzhiTc[obj.position_id])?parseFloat(chongzhiTc[obj.position_id].award_type):1,   
                            }
                            obj.yejiticheng.partner_amount  = obj.price * obj.num *  (obj.yejiticheng.partner_rate / 100);
                            if(obj.yejiticheng.val == '' || obj.yejiticheng.award_type == '')
                            {
                                obj.yejiticheng.ticheng_amount = '';
                            }else{
                                //业绩、手工费设置提成值为固定时，提成金额=分摊%x提成数值x数量
                                if(obj.yejiticheng.award_type == 1)
                                {
                                    //固定金额
                                    obj.yejiticheng.ticheng_amount = (obj.yejiticheng.partner_rate /100) * obj.yejiticheng.val * obj.num;
                                }else{
                                    obj.yejiticheng.ticheng_amount = obj.yejiticheng.partner_amount * obj.yejiticheng.val / 100;
                                }
                            }
                        }
                        obj.shougongfei = '';
                    }
                    fcUserList.push(obj);
                })
                this.fcUserList = fcUserList;
                this.$forceUpdate();
            },
            getZcList(){
                this.$http.api('positionshow',{
					
				}).then(res=>{
                    //处理职称
                    this.zcList = res.list;
                    var zcListByPid = {};
                    res.list.forEach((item,index)=>{
                        zcListByPid[item.id] = item;
                    })
                    this.zcListByPid = zcListByPid;
                    //处理用户
                    var allUserList = res.allUserList?res.allUserList:[];
                    this.allUserList = allUserList;
                    var userListByTid = {};
                    allUserList.forEach((item,index)=>{
                        userListByTid[item.technician_id] = JSON.parse(JSON.stringify(item));
                    })
                    this.userListByTid = userListByTid;
                    //设置默认的全部
                    this.changePosition(0);
				}).catch(res=>{
					
				})
            },
            changePosition(position_id){
                this.selPositionId = position_id;
                var allUserList = JSON.parse(JSON.stringify(this.allUserList));
                allUserList.forEach((item,index)=>{
                    if(position_id == 0 || item.position_id == position_id)
                    {
                        item.is_show = 1;
                    }else{
                        item.is_show = -1;
                    }
                })
                this.showUserList = allUserList;
                return ;
                if(position_id == 0)
                {
                    var showUserList = allUserList;
                }else{
                    var showUserList = [];
                    allUserList.forEach((item,index)=>{
                        if(item.position_id == position_id)
                        {
                            showUserList.push(item);
                        }
                    })
                    
                }
                // this.showUserList.forEach((item,index)=>{
                //     item.is_select = 1;
                // })
                this.showUserList = showUserList;
            },
            changeType(type){
                this.type = type;
            },
			handleCancel(){
                this.$emit("cancel",false);
			},
		}
	}
</script>

<style>
.tichengjine{
    width: 100% !important;
    border: 1px solid #eeeeee;
}
.keyboard-container{
    display: inline-block;
}
.keyboard-box{
    color: #17233d;
    text-align: center;
    width: 270px;
    display: flex;
}
.keyboard-box .k-left {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.keyboard-container button {
    outline: none;
}

.keyboard-box .k-left .key-item {
    flex: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid #dcdee2;
    box-shadow: inset 0 -1px 2px 0 rgb(23 35 61 / 15%);
    border-radius: 6px;
    background: #f8f8f9;
    width: 60px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-family: 微软雅黑;
}

.keyboard-box .k-left .key-item.num{
    background: #fff;
    font-size: 18px;
}

.keyboard-box .k-right{
    width: 60px;
}
.keyboard-box .k-right .btn-item{
    flex: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 10px;
    border: 1px solid #dcdee2;
    box-shadow: inset 0 -1px 2px 0 rgb(23 35 61 / 15%);
    border-radius: 6px;
    background: #f8f8f9;
    font-size: 12px;
    width: 60px;
    height: 106px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.keyboard-box .k-right .btn-item.blue{
    background: #2b85e4;
    color: #fff;
}

.disc-box {
    display: flex;
}

.disc-box .gaijia-form-item{
    display:flex;
    width:180px;
    margin-bottom: 10px;
}

.disc-box .ant-form-item-label{
    width: 80px;
    text-align: right;
}

.award-main .right-box {
    width: 60%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.award-main .right-box .w70{
    width: 70px;
}

.award-main .user-award-box .tbzd{
    padding: 10px;
    font-weight: bold;
    color: #000000;
}

.award-main .user-award-box .tbzd span{
    padding-left: 0px !important;
}

.award-main .right-box .user-award-box-header{
    border: 1px solid #d7dde4;
    border-bottom: 0;
    border-radius: 4px 4px 0 0;
    overflow: hidden;
}


.award-main .right-box .user-award-box-header table {
    width: 100%;
}

.award-main .right-box .user-award-box-header table tr th{
    height: 40px;
    background: #f8f8f9;
}

.award-main .right-box .user-award-box{
    flex: 1;
    overflow: hidden auto;
    border: 1px solid #d7dde4;
    border-top: 0;
    border-radius: 0 0 4px 4px;
}

.award-main .right-box .user-award-box table{
    border: 1px solid #d7dde4;
    width: calc(100% + 2px);
    margin-left: -1px;
    margin-top: -1px;
}



.user-award-box tr {
    transition: background .2s ease-in-out;
}
.award-main .right-box .user-award-box table tr {
    border: inherit;
}

.award-main .right-box .user-award-box table tr td {
    height: 48px;
    padding: 6px;
}
.award-main .right-box .user-award-box table tr[bdt] td{
    border-top: inherit;
}

.award-main .right-box .user-award-box table .user-name{
    max-width: 100%;
    white-space: pre-wrap;
    word-break: break-all;
}
.award-main .right-box .user-award-box table .position_name {
    display: inline-block;
    padding: 0 5px;
    border: 1px solid #2d8cf0;
    color: #2d8cf0;
    border-radius: 8px;
    margin-top: 10px;
    font-size: 12px;
}

.award-main .left-box .job-and-user{
    display: flex;
    /* height: calc(100% - 60px); */
    height: calc(100% - 0px);
    white-space: nowrap;
}

.award-main .left-box .job-and-user .job-list{
    border-right: 1px solid #d7dde4;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 135px;
    flex: none;
    cursor: pointer;
}
.award-main .left-box .job-and-user .job-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

li, ul{
    list-style: none;
}
.award-main .left-box .job-and-user .name{
    overflow: hidden;
    text-overflow: ellipsis;
}
.award-main .left-box .job-and-user .job-list .name {
    height: 36px;
    line-height: 36px;
    margin-top: 10px;
    padding-left: 16px;
}
.award-main .left-box .job-and-user .job-list .name-active {
    background: #f5f7f9;
}




.award-main .left-box .job-and-user .user-list {
    overflow-y: auto;
    overflow-x: auto;
    flex: 1;
}
.award-main .left-box .job-and-user .user-list::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.award-main .left-box .job-and-user .user-list .user-item {
    height: 36px;
    line-height: 36px;
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 5px;
    margin-right: 0;
    width: 175px;
}
.award-main .left-box .job-and-user .user-list .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}
.award-main .left-box .job-and-user .user-list .user-item .name{
    flex: 1;
    margin-left: 10px;
}

.tabs-container-item {
    height: 500px;
    overflow: hidden auto;
}
.award-main {
    font-size: 14px;
    display: flex;
    /* margin-top: 20px; */
}
.award-main .left-box{
    width: 320px;
    margin-right: 20px;
    border: 1px solid #d7dde4;
    border-radius: 4px;
    flex: none;
}
.award-main .left-box .search{
    padding: 10px;
    background: #f5f7f9;
    border-bottom: 1px solid #d7dde4;
    display: flex;
    align-items: center;
}
.award-main .left-box .search .IviewInput{
    margin-left: 10px;
    flex: 0 0 50%;
    margin-right: 10px;
}



.order_box .stitle {
    clear: right;
    margin-left: -1px;
    height: 35px;
    border-bottom: 1px solid #ddd;
    padding-top: 0;
}
.order_box .stitle .close {
    min-width:82px;
    width:88px;
    height: 25px;
    background: #ffffff !important;
    color: #505050;
    text-align: center;
    float: left; font-size:13px;
    margin-right: -1px;
    padding-top:8px;
    box-sizing: content-box;
    line-height: 20px;
    font-weight: normal;
    opacity: 1;
}
.order_box .stitle .open {
    min-width:82px;width:88px;
    height: 26px;
    transform: translateY(1px);
    border-bottom: 3px solid #ec9cb9;
    background: #ffffff;
    color: #505050; font-size:13px;
    text-align: center;
    float: left;
    margin-top: -2px;
    margin-right: -1px;
    padding-top: 8px;
    overflow: hidden;
    box-sizing: content-box;
    line-height: 20px;
    font-weight: normal;
    opacity: 1;
}
.order_box .stitle .open {
    border-bottom: 2px solid #548bde;
    background: #ffffff;
    color: #548bde;
}	
</style>
