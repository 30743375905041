<template>
	<div>
		<div class="pd20" style="height: calc(100vh - 60px);">
			<div class="flex" style="height: 100%;">
				<div  style="width: 30%; height: 100%;">
					<cash-register></cash-register>
				</div>
				<div class="ml20" style="width: 70%; height: 100%;">
					<exhibition-area></exhibition-area>
				</div>
			</div>
		</div>
	</div> 
</template>

<script>
	import cashRegister from './components/Index/cashRegister.vue';
	import exhibitionArea from './components/Index/exhibitionArea.vue';
	export default{
		components:{
			cashRegister,
			exhibitionArea
		},
		data(){
			return{
				
			}
		},
		created() {
			// console.log(this.cashierCartIndex);
		},
	}
</script>

<style>
</style>
