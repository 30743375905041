<template>
	<div class="pd10 cart-goods " :class="{active :index==cashierCartTaocan.selListIndex}"  @click="chooseIt()">
		<div class="flex alcenter">
			<img v-if="datas.cover_img" :src="datas.cover_img" class="cart-goods-img"/>
			<img v-else src="../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="cart-goods-img"/>
			<div class="ml10 alcenter" style="width: 100%;">
				<div class="ft14 ftw400 cl-black text-over4">{{datas.name}}</div>
				<div class="mt8 flex alcenter space">
					
					<div class="mt12">
						<div class="mt16 ft14 cl-price">原价￥{{datas.original_price}}</div>
					</div>
					<div>
						<div class="flex end">
							<div class="flex alcenter">
								<i class="iconfont iconminus_small ft22 cl-notice" v-if="datas.num<=1"/>
								<i class="iconfont iconminus_small ft22 cl-green" @click.stop.prevent="reduce()" v-else/>
								<div class="ft16 cl-black ml8 mr10">{{datas.num}}</div>
								<i class="iconfont iconadd_small1 ft22 cl-theme" @click.stop.prevent="sum()" v-if="datas.num < datas.quota"/>
								<i class="iconfont iconadd_small1 ft22 cl-notice" v-else/>
							</div>
						</div>
						<div class="mt8 flex end ft14 cl-price">实付￥{{price}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="cart-goods-iden" >套餐</div>
		
		<!-- <div style="padding:10px 0 5px;color:#fff;text-align: right;">  v-if="!isVip === false" -->
		<div style="padding:10px 0 5px;color:#fff;text-align: right;">  
			<div class="seltc" @click="selTcUserFunc(index)">{{datas.fcUserList?('已选 '+datas.fcUserList.length+' 人'):'选提成人'}}</div>
		</div>
		
		<div class="cart-goods-choosed" style="line-height: 20px;" v-if="index == cashierCartTaocan.selListIndex">
			<i class="iconfont iconduihao ft10 cl-w  ml20" />
		</div>
	</div>
</template>

<script>

	export default{
		components:{
			
		},
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
			index:{
				type:Number,
				default:-1,
			},
			isVip: {
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			price(){
				let price=0;
				price=this.datas.original_price;
				return ((parseFloat(price)*100 * this.datas.num)/100).toFixed(2);
			}
		},
		data(){
			return{
				list:[
					
				]
			}
		},
		created(){
			console.log('this.cashierCartTaocan',this.cashierCartTaocan)
		},
		methods:{
			selTcUserFunc(index){
				this.$emit('selTcUserFuncCallback',{
					index,
					type:'cashierCartTaocan'
				})
			},
			reduce(){
				this.chooseIt();
				if(this.datas.num<1){
					this.$message.error('无法继续减了');
					return false;
				}
				let cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
				cashierCartTaocan.list[this.index].num=this.datas.num-1;
				cashierCartTaocan.selListIndex = this.index;
				this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
				this.opeCashierCartTaocanTcUserPrice(); //调用common/mixin/common.js下的重置价格计算方法
			},
			sum(){
				this.chooseIt();
				 //e.stopPropagation();//阻止点击事件向上冒泡
				if(this.datas.num>=this.datas.quota){
					this.$message.error('无法继续加了');
					return false;
				}
				let cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
				cashierCartTaocan.list[this.index].num=this.datas.num+1;
				cashierCartTaocan.selListIndex = this.index;
				this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
				this.opeCashierCartTaocanTcUserPrice(); //调用common/mixin/common.js下的重置价格计算方法
			},
			chooseIt(){
				let cashierCartTaocan = JSON.parse(JSON.stringify(this.cashierCartTaocan));
				cashierCartTaocan.selListIndex = this.index;
				this.$store.commit('setCashierCartTaocan',cashierCartTaocan);
			},
		}
	}
</script>

<style>
.seltc{
	display:inline-block;border:1px solid #808695;border-radius: 5px;
    color: #808695;text-align: center;padding: 0 10px;margin-right: 10px;
    cursor: pointer;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
}
	.cart-goods{
		border-bottom: 1px solid #f5f5f5; 
		line-height: 1; 
		position: relative;
	}
	
	.cart-goods.active{
		background: #ECF5FF;
		border: 1px solid #D0E7FF;
	}
	
	.cart-goods-img{
		width: 70px;
		height: 80px
	}
	
	.cart-goods-iden{
		position: absolute;
		top:10px;
		left:10px;
		
		background: red;
		color: #ffffff;
		padding: 1px 4px;
		font-size: 12px;
		border-radius: 2px;
	}
	
	.cart-goods-choosed{
		position: absolute;
		top:0;
		right:0;
		width: 0; height: 0;
		 border-color: transparent #F56C6C; /*上下颜色 左右颜色*/
		 border-width: 0 40px 40px 0;
		 border-style: solid;
	}
</style>
