<template>
	<div>
		<a-modal title="关联商品" :width="480" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					确定选择
				</a-button>
			</template>
			<div>
				<a-spin :spinning="loading">
					<div class="flex center">
						<a-input-search placeholder="搜索商品名称" style="width: 420px" @search="onSearch" v-model="search.name" />
					</div>
					
					<div class="mt16">
						<div class="flex center wrap">
							<template v-for="(item,index) in getData">
								<div class="edit-look-choose-goods-item" v-if="item.is_select==1" >
									<div class="flex space ">
										<div class="flex">
											<img  :src="item.cover_img"/>
											
											<div class="ml10">
												<div class="text-over4 ft14 ftw400 cl-black">{{item.name}} </div>
												<div class="mt10 ft12 ftw400 cl-notice text-over4">原价：¥{{item.original_price}}</div>
											</div>
										</div>
										
										<div>
											<div class="flex alcenter" >
												<i class="iconfont iconminus_small ft24 clickAct cl-notice" v-if="item.num<=0" />
												<i class="iconfont iconminus_small ft24 clickAct cl-theme" v-else-if="item.num==1"  @click="del(index)" />
												<i class="iconfont iconminus_small ft24 clickAct cl-theme" v-else @click="reduce(index)"/>
												<div class="ml10 mr10 ft14 ftwblod cl-main">{{item.num}}</div>
												<i class="iconfont iconadd_small1 ft24 clickAct cl-theme"  @click="plus(index)"/>
											</div>
										</div>
									</div>
								</div>
								
								<div class="edit-look-choose-goods-item " v-else>
									<div class="flex space ">
										<div class="flex">
											<img  :src="item.cover_img"/>
											
											<div class="ml10">
												<div class="text-over4 ft14 ftw400 cl-black">{{item.name}} </div>
												<div class="mt10 ft12 ftw400 cl-notice text-over4">原价：¥{{item.original_price}}</div>
											</div>
										</div>
										
										<div>
											<div  class="flex alcenter" >
												<i class="iconfont iconadd_small1 ft24 clickAct cl-theme"  @click="checkIt(index)"/>
											</div>
										</div>
									</div>
								</div>
							</template>
						</div>
					</div>
					
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items />
					</div>
				</a-spin>
				
			</div>
		</a-modal>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../../common/mixin/list.js';
	export default {
		mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
			defaultList:{
				type:Array,
				default:function(){
					return new Array;
				},
			},
		},
		data() {
			return {
				loading: false,
				confirmLoading: false,
				pagination: {
					current: 1,
					pageSize: 4, //每页中显示10条数据
					total: 0,
				},
				search:{
					name:'',
				},
				select_ids_data:[],
				datas:[],
			}
		},
		created() {
            var defaultList = JSON.parse(JSON.stringify(this.defaultList));
			for(var i in defaultList){
				this.select_ids_data.push(defaultList[i]);
			}
			this.getLists();
		},
		computed:{
		
			getData(){
				let datas =JSON.parse(JSON.stringify(this.datas));
				for(var i in datas){
					for(var j in this.select_ids_data){
						let status = (datas[i].goods_id==this.select_ids_data[j].goods_id)
						if(status){
							datas[i].is_select=1;
							datas[i].select_index=j;
							datas[i].num=this.select_ids_data[j].num;
						}
					}
				}
				console.log(datas);
				return datas;
			}
		},
		
		methods: {
			
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getGoodsList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			onSearch(){
				this.pagination.current=1;
				this.getLists();
			},
			
			checkIt(index){
				this.select_ids_data.push({
					goods_id:this.datas[index].goods_id,
                    cover_img:this.datas[index].cover_img,
                    name:this.datas[index].name,
					num:1,
					original_price:this.datas[index].original_price,
					type:'goods',
					is_zs:1
				});
			},
			
			reduce(index){
				let select_index=this.getData[index].select_index;
				this.select_ids_data[select_index].num=this.select_ids_data[select_index].num-1;
			},
			
			plus(index){
				let select_index=this.getData[index].select_index;
				this.select_ids_data[select_index].num=this.select_ids_data[select_index].num+1;
			},
			
			del(index){
				let select_index=this.getData[index].select_index;
				this.select_ids_data.splice(select_index,1);
			},
			
			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},
	
			/**
			 * 提交数据
			 */
			onSubmit() {
				if(this.select_ids_data.length<=0){
					this.$message.error('请选择商品');
					return false;
				}
				let defaultList = JSON.parse(JSON.stringify(this.select_ids_data));
				//this.$emit('input',defaultList);
				this.$emit("ok",defaultList);
			},
			
		}
	}
</script>

<style>
	.edit-look-choose-goods-item{
		width: 420px;
		height: 80px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #EBEDF5;
		margin-bottom: 10px;
		padding: 10px;
	}
	
	.edit-look-choose-goods-item img{
		width: 60px;
		height: 60px;
	}
	
	
	.edit-look-choose-goods-item-select{
		width: 24px;
		height: 24px;
		border-radius: 4px;
		border: 2px solid #A6AEC2;
		cursor: pointer;
	}
	
	.edit-look-choose-goods-item-selected{
		width: 24px;
		height: 24px;
		background: #4772FF;
		line-height: 24px;
		text-align: center;
	}
</style>
