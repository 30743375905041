<template>
	<div >
		<div class="pd10">
			<a-spin :spinning="loading">
				<a-tabs v-model="aTabKey" @change="aTabChange">
					<a-tab-pane :key="item.key" :tab="item.title" v-for="(item,index) in aTab" >
						
					</a-tab-pane>
				</a-tabs>
				<div class="form-search-box " style="display: flex;align-items: center;" v-if="aTabKey == 'goods'">
					<div  class="tabs_selected" :style="{'width':JSON.stringify(cashierMember) !='{}'?'226px':'150px'}">
						<div class="tabs_item " v-if="JSON.stringify(cashierMember) !='{}'" :class="aTabKeyGoodstype == 3?'tabs_item-active':''" @click="changeaTabKeyGoodstype(3)">已购项目</div>
						<div  class="tabs_item " :class="aTabKeyGoodstype == 1?'tabs_item-active':''" @click="changeaTabKeyGoodstype(1)">产品</div>
						<div class="tabs_item " :class="aTabKeyGoodstype == 2?'tabs_item-active':''" @click="changeaTabKeyGoodstype(2)">项目</div>
					</div>
					<a-form layout="inline" v-if="aTabKeyGoodstype == 1">
						<a-form-item label="名称">
							<a-input v-model="search.name" placeholder="请输入商品名称"></a-input>
						</a-form-item>
						
						<a-form-item label="第一分类">
							 <a-select v-model="search.pid" style="width: 200px" @change="handleChange">
								 <a-select-option :value="0" >
								   请选择
								 </a-select-option>
							      <a-select-option :value="item.category_id" v-for="(item,index) in first_type_list" :key="index">
							        {{item.name}}
							      </a-select-option>
							 </a-select>
						</a-form-item>
						
						<a-form-item label="第二分类" v-if="search.pid!=0">
							 <a-select v-model="search.category_id" style="width: 200px"  @change="secondHandleChange">
								 <a-select-option :value="0" >
								   请选择
								 </a-select-option>
							      <a-select-option :value="item.category_id" v-for="(item,index) in second_type_list" :key="index">
							        {{item.name}}
							      </a-select-option>
							 </a-select>
						</a-form-item>

            			<input type="hidden"  value="" v-model="search_category_id">
						
						<a-form-item>
							<a-button @click="searchAct" type="primary">查询</a-button>
							<a-button @click="cancelAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
					<a-form layout="inline" v-if="aTabKeyGoodstype == 2">
						<a-form-item label="名称">
							<a-input v-model="xmsearch.name" placeholder="请输入项目名称"></a-input>
						</a-form-item>
						
						<a-form-item label="项目分类">
							 <a-select v-model="xmsearch.category_id" style="width: 200px" >
								 <a-select-option :value="0" >
								   请选择
								 </a-select-option>
							      <a-select-option :value="item.project_type_id" v-for="(item,index) in xm_first_type_list" :key="index">
							        {{item.name}}
							      </a-select-option>
							 </a-select>
						</a-form-item>
						
						<a-form-item>
							<a-button @click="searchXmAct" type="primary">查询</a-button>
							<a-button @click="cancelXmAct" class="ml10">取消</a-button>
						</a-form-item>
					</a-form>
				</div>
				<div class="" v-if="aTabKey == 'chongzhi'">
					<recharge></recharge>
				</div>
				<div class="" v-if="aTabKey == 'taocan'">
					<buy-taoka></buy-taoka>
				</div>
				<div class=" pd20" v-if="aTabKey == 'goods' && aTabKeyGoodstype == 1">
					<div class="flex wrap" v-if="getDatas.length>0 ">
						<template v-for="(item,index) in getDatas">
							<div class="goods-data-item" :class="{active : item.is_select==1}" >
								<img v-if="item.cover_img" :src="item.cover_img" class="goods-data-item-cover-img"/>
								<img v-else src="../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="goods-data-item-cover-img"/>
								<div class="pd10">
									<div class="ft12 ftw400 cl-black text-over4">{{item.name}}</div>
									<div class="flex alcenter space mt10" v-if="item.is_select==1">
										<div class="cl-price ft14">￥{{item.original_price}}</div>
										<div class="choose-button" v-if="item.is_have_sku==1" @click="chooseSkuAct(index)">规格</div>
										<div class="choose-button" v-else >选择</div>
									</div>
									<div class="flex alcenter space mt10" v-else>
										<div class="cl-price ft14">￥{{item.original_price}}</div>
										<div class="choose-button" v-if="item.is_have_sku==1" @click="chooseSkuAct(index)">规格</div>
										<div class="choose-button" v-else  @click="chooseIt(index)">选择</div>
									</div>
								</div>
								<div class="goods-data-item-choose flex alcenter center" v-if="item.is_select==1">
									<i class="iconfont iconyes ft28 cl-green"/>
								</div>
								
								<div class="goods-data-item-quota">库存：{{item.quota}}</div>
							</div>
						</template>
					</div>
					
					<div class="mt20 flex center" v-else>
						<a-empty/>
					</div>
								
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="pagination.total" v-model="pagination.current" :pageSize="pagination.pageSize" show-less-items :aTabKeyGoodstype='aTabKeyGoodstype'/>
					</div>
					
					<div v-if="chooseSkuVisible">
						<choose-sku :visible="chooseSkuVisible" :goods="goods" @cancel="cancelChooseSku" @ok="okChooseSku"></choose-sku>
					</div>
				</div>
				<div class=" pd20" v-if="aTabKey == 'goods' && aTabKeyGoodstype == 2">
					<div class="flex wrap" v-if="getXmDatas.length>0 ">
						<template v-for="(item,index) in getXmDatas">
							<div class="goods-data-item" :class="{active : item.is_select==1}" >
								<img v-if="item.cover_img" :src="item.cover_img" class="goods-data-item-cover-img"/>
								<img v-else src="../../../../../assets/image/41f5d53f5e9c32b4.jpg" class="goods-data-item-cover-img"/>
								<div class="pd10">
									<div class="ft12 ftw400 cl-black text-over4">{{item.full_name}}</div>
									<div class="flex alcenter space mt10" v-if="item.is_select==1">
										<div class="cl-price ft14">￥{{item.market_price}}</div>
										<div class="choose-button">选择</div>
									</div>
									<div class="flex alcenter space mt10" v-else>
										<div class="cl-price ft14">￥{{item.market_price}}</div>
										<div class="choose-button"  @click="chooseIt(index)">选择</div>
									</div>
								</div>
								<div class="goods-data-item-choose flex alcenter center" v-if="item.is_select==1">
									<i class="iconfont iconyes ft28 cl-green"/>
								</div>
							</div>
						</template>
					</div>
					
					<div class="mt20 flex center" v-else>
						<a-empty/>
					</div>
								
					<div class="flex center mt20">
						<a-pagination @change="pageChange" :total="xmpagination.total" 
							v-model="xmpagination.current" :pageSize="xmpagination.pageSize" show-less-items :aTabKeyGoodstype='aTabKeyGoodstype'/>
					</div>
				</div>
				<div class=" " v-if="aTabKey == 'goods' && aTabKeyGoodstype == 3">
					<have-taoka></have-taoka>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	import {listMixin} from '../../../../../common/mixin/list.js';
	import chooseSku from './goods/modal/sku.vue';
	import recharge from '../../newindex/recharge.vue';
	import buyTaoka from '../../newindex/buy-taoka.vue';
	import haveTaoka from '../../newindex/have-taoka.vue';
	export default{
		mixins:[listMixin],
		components:{
			chooseSku,
			recharge,
			buyTaoka,
			haveTaoka
		},
		data(){
			return{
				loading:false,
				chooseSkuVisible:false,
				goods:{},
				pagination: {
					current: 1,
					pageSize: 8, //每页中显示10条数据
					total: 0,
				},
				search:{
					name:'',
					pid:0,
					category_id:0,
				},
				type_list:[],
				datas:[],
				search_category_id:'',
				aTabKey:'goods',
				aTab:[
					{key:'goods',title:'项目/产品'},
					{key:'taocan',title:'套餐'},
					{key:'chongzhi',title:'充值'}
				],
				aTabKeyGoodstype:1,
				xmsearch:{
					name:'',
					category_id:0,
				},
				xm_type_list:[],
				xmdatas:[],
				xmpagination: {
					current: 1,
					pageSize: 8, //每页中显示10条数据
					total: 0,
				},
				buyxmdatas:[],
			}
		},
		created() {
			this.aTabKey = this.cashierCartTabType;
			this.setCommonLocalCache(); //清空缓存的分成配置
			this.gerperinfo();//拉取默认的分成配置项
		},
		computed:{
			xm_first_type_list(){
				console.log('xm_type_list',this.xm_type_list)
				let arr = new Array;
				for( var i in this.xm_type_list){
					arr.push(this.xm_type_list[i]);
				}
				return arr;
			},
			
			first_type_list(){
				let arr = new Array;
				for( var i in this.type_list){
					if(this.type_list[i].pid==0){
						arr.push(this.type_list[i]);
					}
				}
				return arr;
			},
			
			second_type_list(){
				let arr = new Array;
				if(this.search.pid !=0){
					for( var i in this.type_list){
						if(this.type_list[i].pid==this.search.pid){
							arr.push(this.type_list[i]);
						}
					}
				}
				return arr;
			},
			getXmDatas(){
				let datas =JSON.parse(JSON.stringify(this.xmdatas));
				for(var i in datas){
					for(var j in this.cashierCart){
						if(this.cashierCart[j].type=='xiangmu'){
							if(!this.cashierCart[j].member_once_card_id && this.cashierCart[j].goods_id==datas[i].project_id){
								datas[i].is_select=1;
							}
						}
					}
				}
				return datas;
			},
			getDatas(){
				let datas =JSON.parse(JSON.stringify(this.datas));
				for(var i in datas){
					for(var j in this.cashierCart){
						if(this.cashierCart[j].type=='goods'){
							if(!this.cashierCart[j].member_once_card_id && this.cashierCart[j].goods_id==datas[i].goods_id){
								datas[i].is_select=1;
							}
						}
					}
				}
				return datas;
			}
		},
		methods:{
			changeaTabKeyGoodstype(type){
				this.aTabKeyGoodstype = type;
				if(type == 1)
				{
					//显示的是产品
					if(this.datas.length < 1)
					{
						this.loading=false;
						this.getLists();
					}
				}else if(type == 2){
					//显示的是项目
					if(this.xmdatas.length < 1)
					{
						this.loading=false;
						this.getXmLists();
					}
				}else if(type == 3){
					//显示的是已购套餐
					if(this.buyxmdatas.length < 1)
					{
						this.loading=false;
						//this.getXmLists();
					}
				}
			},
			
			aTabChange(type){
				this.$store.commit('setCashierCartTabType',type);
				console.log('aTabChange',type)
			},
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('getGoodsList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
					name:this.search.name,
					category_id:this.search.category_id,
					search_category_id:this.search_category_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					if(res.type_list){
						this.type_list=res.type_list;
					}
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			getXmLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('projectshow',{
					limit:this.xmpagination.pageSize,
					page:this.xmpagination.current,
					name:this.xmsearch.name,
					search_category_id:this.xmsearch.category_id,
				}).then(res=>{
					this.xmpagination.total=res.total;
					this.xmdatas=res.list;
					if(res.xm_type_list){
						this.xm_type_list=res.xm_type_list;
					}
					this.loading=false;
				}).catch(res=>{
					this.loading=false;
				})
			},
			searchXmAct(){
				this.xmpagination.current = 1;
				this.getXmLists();
			},
			cancelXmAct(){
				this.xmpagination.current = 1;
				this.xmsearch.name = '';
				this.xmsearch.category_id = 0;
				this.$forceUpdate();
				this.getXmLists();
			},
			gerperinfo(){
				this.$http.api('gerperinfo',{
					
				}).then(res=>{
					this.setCommonLocalCache('perinfo',res);
					console.log('gerperinfo res',res)
				}).catch(res=>{
					this.loading=false;
				})
			},
			setCommonLocalCache(key,value){
				var cacheKey = 'cash_goods_set_common_local_cache';
				if(!key && !value)
				{
					localStorage.removeItem(cacheKey);
					return ;
				}
				var cache =  localStorage.getItem(cacheKey);
				cache = cache?JSON.parse(cache):{};
				cache[key] = value;
				localStorage.setItem(cacheKey,JSON.stringify(cache));
			},
			
			handleChange(){
				this.search.category_id=0;
				this.search_category_id=this.search.pid;
			},
			secondHandleChange(){
				this.search_category_id=this.search.category_id;
				if(this.search.category_id == 0){
				this.search_category_id=this.search.pid;
				}
			},
			
			chooseIt(index){
				var aTabKeyGoodstype = this.aTabKeyGoodstype;
				let cart=JSON.parse(JSON.stringify(this.cashierCart));
				for(var i in cart){
					switch(aTabKeyGoodstype)
					{
						case 1:
							//产品
							if(cart[i].type=='goods'){
								if(!cart[i].member_once_card_id && this.datas[index].goods_id == cart[i].goods_id){
									return false;
								}
							}
							break;
						case 2:
							//项目
							if(cart[i].type=='xiangmu'){
								if(!cart[i].member_once_card_id && this.xmdatas[index].project_id == cart[i].goods_id){
									return false;
								}
							}
							break;
					}
				}
				console.log('aTabKeyGoodstype',aTabKeyGoodstype)
				if(aTabKeyGoodstype == 1)
				{
					var obj = {
						goods_id:this.getDatas[index].goods_id,
						num:1,
						type:'goods',
						name:this.getDatas[index].name,
						cover_img:this.getDatas[index].cover_img,
						original_price:this.getDatas[index].original_price,
						vip_price:this.getDatas[index].vip_price,
						sku_id:0,
						quota:this.getDatas[index].quota,
						is_vip_discount: this.getDatas[index].is_vip_discount
					}
				}else if(aTabKeyGoodstype == 2)
				{
					var obj = {
						goods_id:this.getXmDatas[index].project_id,
						num:1,
						type:'xiangmu',
						name:this.getXmDatas[index].full_name,
						cover_img:this.getXmDatas[index].cover_img,
						original_price:this.getXmDatas[index].market_price,
						vip_price:this.getXmDatas[index].market_price,
						sku_id:0,
						quota:999999999,
						is_vip_discount: 0
					}
				}
				if(obj)
				{
					cart.push(obj);
					// console.log('cart',cart);
					// return ;
					this.$store.commit('setCashierCart',cart);
				}
				
			},
			chooseSkuAct(index){
				 this.goods=this.getDatas[index];
				 this.chooseSkuVisible=true;
			 },
			 cancelChooseSku(){
				 this.chooseSkuVisible=false;
			 },
			okChooseSku(){
			 	this.chooseSkuVisible=false;
				this.getLists();
			 },
		},
		 
	}
</script>

<style>
.form-search-box {
	border-bottom:2px solid #eeeeee;
}
.form-search-box .tabs_selected {
    display: flex;
    margin: 0px 10px 0px 0;
    background: #e5f6ff;
    border-radius: 20px;
	width: 150px;
	height: 35px;
}

.form-search-box .tabs_selected .tabs_item{
    width: 75px;
    height: 35px;
    line-height: 35px;
    background: #e5f6ff;
    color: #2d8cf0;
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}
.form-search-box .tabs_selected .tabs_item-active{
    background: #2d8cf0;
    color: #fff;
}
	.goods-data-item{
		width: 24%;
		height: 250px;
		margin-right: 1%;
		margin-bottom: 1%;
		border: 1px solid #F4F5F7;
		box-shadow:0px 15px 10px -15px #000;
		line-height: 1;
		position: relative;
	}
	
	.goods-data-item.active{
		border: 1px solid #00C657;
	}
	
	.goods-data-item-choose{
		position: absolute;
		top:0;
		left: 0;
		width: 100%;
		height: 180px;
	}
	.goods-data-item-quota{
		position: absolute;
		top:0;
		right: 0;
		padding: 4px 8px;
		background: #F56C6C;
		color: #FFFFFF;
		border-radius: 4px;
	}
	
	.goods-data-item-cover-img{
		width: 100%;
		height: 180px;
	}
	
	.choose-button{
		padding: 8px 14px;
		background: #4772FF;
		color: #ffffff;
		border-radius: 4px;
	}
</style>
